<template>
  <div class="row row--no-gutters row--vcenter row--between">
    <span class="siblings-nav__text">Połączone projekty</span>
    <base-spinner v-if="loading" small />
    <div v-else class="row row--no-gutters row--vcenter">
      <button
        class="siblings-nav__left"
        @click="changeSibling('previous')"
      ></button>
      <span
        v-for="sibling in siblings"
        :key="sibling"
        class="siblings-nav__item"
        :class="current === sibling && 'siblings-nav__item--current'"
      ></span>
      <button
        class="siblings-nav__right"
        @click="changeSibling('next')"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    siblings: Array,
    current: String,
  },
  methods: {
    changeSibling(direction) {
      const currentIndex = this.siblings.findIndex(s => s === this.current);
      let nextIndex =
        direction === 'previous' ? currentIndex - 1 : currentIndex + 1;
      if (nextIndex < 0) {
        nextIndex = this.siblings.length - 1;
      }
      if (nextIndex > this.siblings.length - 1) {
        nextIndex = 0;
      }
      this.$emit('change', {
        slug: this.siblings[nextIndex],
        direction: direction === 'previous' ? 'left' : 'right',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.siblings-nav__text {
  font-size: 0.9em;
  color: $primary;
  margin-right: 1.5em;
}
.siblings-nav__item {
  display: block;
  margin: 0 2px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: #696965;
}
.siblings-nav__item--current {
  width: 1em;
  background: $primary;
}
.siblings-nav__left,
.siblings-nav__right {
  padding: 0.5em 0.7em;

  @include mq('phone') {
    padding: 0.6em 1.3em;
  }
  @include mq('tablet') {
    padding: 0.3em;
  }

  &:hover {
    &::after {
      border-color: $secondary;
    }
  }
}
.siblings-nav__left {
  &::after {
    @include arrowPseudo($primary, 'left');
  }
}
.siblings-nav__right {
  &::after {
    @include arrowPseudo($primary, 'right');
  }
}
</style>
