<template>
  <div class="item-modules-row">
    <div
      :class="['item-modules-item', {
        'item-modules-item--active': module.active,
        'item-modules-item--hover': index === hovered,
      }]"
      v-for="(module, index) in itemModules"
      :key="`${module.name}-${index}`"
      @click="!module.active ? addProjectToModule(module) : removeProjectFromModule(module)"
      @mouseenter="setHovered(index)"
      @mouseleave="setHovered(null)"
    >
      {{ module.name }}
      <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 0.666992H5V7.33366L3 5.60723L1 7.33366V0.666992Z" fill="currentColor" stroke="currentColor" stroke-width="0.666667" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ItemModules',
  props: {
    item: {
      type: Object,
      required: true,
    },
    isBookmarked: {
      type: Boolean
    }
  },
  data() {
    return {
      enabled: [],
      hovered: null,
    }
  },
  computed: {
    ...mapGetters('list', {
      userModules: 'modules',
      pksByModule: 'pksByModule',
      favoriteHated: 'favoriteHated',
      api: 'api',
    }),
    itemModules: function () {
      let modules = [];
      for (let i in this.pksByModule) {
        let index = this.pksByModule[i].indexOf(this.item.id.toString());
        if (index > -1) {
          let module = Object.assign({}, this.userModules.find(module => module.id.toString() === i));
          let excluded = this.favoriteHated.excluded_from_module[parseInt(this.item.id)];
          module.active = !(this.favoriteHated.hateful.indexOf(this.item.id.toString()) >= 0 || (excluded && excluded.indexOf(module.id) >= 0));
          modules.push(module);
        }
      }
      const enabled = modules.length > 0 ? modules.filter(module => module.active) : 9999;
      this.$emit('update:projectModules', enabled);
      return modules;
    }
  },
  methods: {
    ...mapActions('list', [
      'getFavoriteHated',
    ]),
    ...mapMutations('list', [
      'includeProjectToModule',
      'excludeProjectFromModule',
    ]),
    async addProjectToModule (module) {
      const response = await this.$api.put(`v0/${this.api.list}/toggle_exclude_from_module/${module.id}/${this.item.id}`);
      if (response.status === 200) {
        this.setHovered(null);
        // this.includeProjectToModule({ moduleId: module.id, projectId: this.item.id });
      }
      this.getFavoriteHated();
    },
    async removeProjectFromModule (module) {
      const response = await this.$api.delete(`v0/${this.api.list}/toggle_exclude_from_module/${module.id}/${this.item.id}`);
      if (response.status === 200) {
        this.setHovered(null);
        // this.excludeProjectFromModule({ moduleId: module.id, projectId: this.item.id });
      }
      this.getFavoriteHated();
    },
    setHovered(name) {
      this.hovered = name;
    },
  }
}
</script>

<style scoped lang="scss">
.item-modules-row {
  display: flex;
  flex-direction: row;
  grid-gap: 15px;
  flex-wrap: wrap;
}
.item-modules-item {
  white-space: nowrap;
  border-radius: 27px;
  padding: 8px 14px;
  background-color: rgba(216, 216, 216, 0.5);
  color: $gray-1;
  font-size: 12px;
  cursor: pointer;
  transition: color 50ms ease-in-out;
  &:not(&--active) {
    &.item-modules-item--hover {
      @include mq('tablet') {
        color: #fff;
      }
    }
  }
  &--active {
    background-color: #FFF;
    color: $primary;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    &.item-modules-item--hover {
      @include mq('tablet') {
        color: $error;
      }
    }
  }
}
</style>
