<script>
export default {
  name: 'TextVersionsComparisonSelect',
  data() {
    return {
      selectValue: this.$attrs.value,
      open: false,
    };
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    valueItem: function() {
      if (this.selectValue) {
        const index = this.options.findIndex(
          item => item.value === this.selectValue
        );
        const item = this.options[index];
        return item.data;
      }
      return null;
    },
  },
  watch: {
    open: {
      immediate: true,
      handler: function(v) {
        this.$emit('toggled', v);
      },
    },
    '$attrs.value': function(v) {
      this.selectValue = v;
    },
  },
  methods: {
    setValue(item) {
      if (item.disabled) {
        return;
      }
      this.selectValue = item.value;
      this.$emit('input', item.value);
    },
  },
};
</script>

<template>
  <div
    :class="[
      'text-versions-comparison-select',
      {
        'text-versions-comparison-select--open': open,
      },
    ]"
    v-click-outside="() => (open = false)"
    @click="open = !open"
  >
    <div class="text-versions-comparison-select__value">
      <div class="text-versions-comparison-select__valueContent">
        <template v-if="valueItem">
          <div class="text-versions-comparison-select__valueDate">
            {{ valueItem.date }} r.
          </div>
          <div class="text-versions-comparison-select__valueTitle">
            {{ valueItem.description || valueItem.stage_description }}
          </div>
          <img
            :src="
              require(`@/assets/icons/File${
                valueItem.format
                  ? valueItem.format.toUpperCase()
                  : valueItem.linkFormat
                  ? valueItem.linkFormat.toUpperCase()
                  : null
              }.svg`)
            "
            width="14"
          />
        </template>
      </div>
      <svg
        :style="
          `transition: transform 100ms ease-in-out; transform: rotate(${
            open ? `180deg` : `0deg`
          })`
        "
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_3856_16437"
          style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect
            x="24"
            y="24"
            width="24"
            height="24"
            transform="rotate(-180 24 24)"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_3856_16437)">
          <path
            d="M12 13.2L16.6 8.6L18 10L12 16L6 10L7.4 8.6L12 13.2Z"
            fill="#9B9B9B"
          />
        </g>
      </svg>
    </div>
    <Transition name="slide-select">
      <div v-if="open" class="text-versions-comparison-select__list">
        <div
          v-for="(option, i) in options"
          :key="`option-${i}`"
          :class="[
            'text-versions-comparison-select__listOption',
            {
              'text-versions-comparison-select__listOption--disabled':
                option.disabled || option.value === selectValue,
            },
          ]"
          @click="setValue(option)"
        >
          <div>{{ option.data.date }} r.</div>
          <div>
            {{ option.label || option.data.stage_description }}
          </div>
          <img
            :src="
              require(`@/assets/icons/File${
                option.data.format
                  ? option.data.format.toUpperCase()
                  : option.data.linkFormat
                  ? option.data.linkFormat.toUpperCase()
                  : null
              }.svg`)
            "
            width="14"
          />
        </div>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss">
.slide-select-enter-active,
.slide-select-leave-active {
  transition: opacity 250ms, transform 200ms;
}
.slide-select-enter {
  opacity: 0;
  transform: translateY(-2rem);
}
.slide-select-leave-to {
  opacity: 0;
  transform: translateY(-2rem);
}
.text-versions-comparison-select {
  background-color: white;
  border-radius: 10px;
  padding: 0.8rem 1.2rem;
  position: relative;
  cursor: pointer;
  z-index: 1;
  &--open {
    box-shadow: 0px 8px 14px -1px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &__value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 24px;
    min-width: 0;
    &Date {
      white-space: nowrap;
    }
  }
  &__valueContent {
    .text-versions-comparison-select__valueTitle {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
      flex: 1;
    }
  }
  &__valueContent,
  &__listOption {
    display: grid;
    grid-template-columns: 80px 1fr 12px;
    grid-gap: 10px;
    align-items: center;
    > div {
      font-weight: 700;
      &:first-child {
        margin-left: 5px;
        color: $gray-3;
        font-size: 0.85rem;
      }
      &:last-of-type {
        color: $gray-1;
        font-size: 1rem;
      }
    }
  }
  &__list {
    position: absolute;
    top: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: white;
    padding: 1rem 1.2rem;
    left: 0;
    width: 100%;
    box-shadow: 0px 8px 14px -1px rgba(0, 0, 0, 0.1);
    max-height: 50vh;
    overflow-y: auto;
    &Option {
      padding-block: 0.3rem;
      &--disabled {
        opacity: 0.4;
        cursor: default;
      }
    }
  }
}
</style>
