import { markHighlights } from '../markHighlights';

export const prepareSummary = (summary, highlights, isSearch) =>
  Object.entries(summary).map(([type, val]) => {
    const legalBasis = !isSearch && type === 'Podstawa prawna';
    let value = val;
    if (typeof val === 'object') {
      if (!val.link) {
        value = val.number;
      }
    } else {
      if (isSearch) {
        value = markHighlights(
          value,
          legalBasis
            ? highlights.legal_basis_keywords_list
            : highlights.description_keywords_list
        );
      }
    }
    return {
      type,
      value,
      highlight: legalBasis && highlights.legal_basis_contains_keywords,
    };
  });
