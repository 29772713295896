import { prepareInfo } from './prepareInfo';
import { prepareListInfo } from './prepareListInfo';
import { prepareTextLinks } from './prepareTextLinks';
import { prepareSummary } from './prepareSummary';
import { prepareAttachments } from './prepareAttachments';
import { groupLinks } from './groupLinks';

export const createPrepareItem = (summaryKey, listInfoMeta) => {
  const specificListInfo = prepareListInfo(listInfoMeta);
  return {
    prepareItem: (
      data,
      descriptions = null,
      highlights = {},
      isSearch = false
    ) => {
      return {
        id: data.pk,
        slug: data.slug,
        title: data.title,
        initiativeType: data.type_initiative_full_text,
        // titleHighlights: Boolean(highlights.title_contains_keywords),
        titleSearchHighlights: Boolean(
          highlights.title_contains_search_keywords
        ),
        status: data.status,
        summary: prepareSummary(data[summaryKey], highlights, isSearch),
        links: groupLinks(data.links),
        timeline: data.events_list || [],
        info: prepareInfo(data, highlights, descriptions),
        infoLists: specificListInfo(data, highlights),
      };
    },
    prepareSibling: (
      data,
      highlights = {},
      isSearch = false,
      descriptions = null
    ) => ({
      id: data.pk,
      slug: data.slug,
      initiativeType: data.type_initiative_full_text,
      summary: prepareSummary(data[summaryKey], highlights, isSearch),
      links: groupLinks(data.links),
      timeline: data.events_list || [],
      info: prepareInfo(data, highlights, descriptions),
      infoLists: specificListInfo(data, highlights),
    }),
    prepareItemAdditional: (data, existingInfoLinks = [], highlights = {}) => ({
      infoLists: existingInfoLinks.concat(specificListInfo(data, highlights)),
      textLinks: prepareTextLinks(data),
      attachments: prepareAttachments(data),
    }),
  };
};
