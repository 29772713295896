var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"versions-navigation"},[(_vm.showSearchNavigationMobile || _vm.showMonitoredNavigationMobile)?_c('div',{staticClass:"versions-navigation--top"},[(_vm.showSearchNavigationMobile)?[_c('img',{attrs:{"src":require(`@/assets/icons/${_vm.navigatorSearch.icon}`)}}),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.search))])]:(_vm.showMonitoredNavigationMobile && _vm.navigatorMonitored.total)?[_c('img',{attrs:{"src":require(`@/assets/icons/${_vm.navigatorMonitored.icon}`)}}),_vm._v(" "),_c('div',[_vm._v("monitorowane frazy")])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"versions-navigation--topDate"},[_vm._v("\n      "+_vm._s(_vm.versions[0].date)+"\n    ")])],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"versions-navigation--main"},[(_vm.versions && _vm.versions.length > 1)?_c('div',{staticClass:"versions-navigation--list",on:{"click":function($event){return _vm.$emit('toggle-versions', true)}}},[_c('img',{attrs:{"src":require('@/assets/icons/FloatingBar.svg')}}),_vm._v(" "),_c('div',[_c('div',{staticClass:"desktop"},[_vm._v("wersje")]),_vm._v(" "),_c('div',[_vm._t("versionDate",function(){return [_vm._v("\n            "+_vm._s(_vm.versions[0].date)+"\n          ")]})],2)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"versions-navigation--navigators"},[(
          _vm.navigatorMonitored.total &&
            (_vm.screenWidth >= 1024 || _vm.showMonitoredNavigationMobile)
        )?_c('div',{staticClass:"versions-navigation--navigator"},[_c('img',{attrs:{"src":require(`@/assets/icons/${
              _vm.navigatorMonitored.total
                ? _vm.navigatorMonitored.icon
                : 'LensDisabled.svg'
            }`)}}),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.navigatorMonitored.title))]),_vm._v(" "),(_vm.navigatorMonitored.total)?_c('div',{staticClass:"versions-navigation--counter"},[_c('span',[_vm._v(_vm._s(_vm.navigatorMonitored.current))]),_vm._v("/"+_vm._s(_vm.navigatorMonitored.total)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.showMonitoredNavigation)?[_c('div',{staticClass:"versions-navigation--arrow",on:{"click":function($event){return _vm.prevHighlight('navigatorMonitored')}}},[_c('img',{attrs:{"src":require('@/assets/icons/ArrowUpNav.svg')}})]),_vm._v(" "),_c('div',{staticClass:"versions-navigation--arrow",on:{"click":function($event){return _vm.nextHighlight('navigatorMonitored')}}},[_c('img',{attrs:{"src":require('@/assets/icons/ArrowDownNav.svg')}})])]:_c('button',{class:[
            'versions-navigation--show',
            {
              'versions-navigation--show-disabled':
                _vm.navigatorMonitored.total <= 0,
            },
          ],on:{"click":function($event){_vm.navigatorMonitored.total ? _vm.showMonitoredNavigationHandler() : null}}},[_vm._v("\n          Pokaż\n        ")])],2):_vm._e(),_vm._v(" "),(
          _vm.navigatorSearch.total || _vm.showSearchInput || _vm.showSearchInputTimeout
        )?_c('div',{staticClass:"versions-navigation--navigator"},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require(`@/assets/icons/${_vm.navigatorSearch.icon}`)},on:{"click":function($event){_vm.showSearchInput = true}}}),_vm._v(" "),(_vm.showSearchInput)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchInputValue),expression:"searchInputValue"}],ref:"searchInputRef",staticClass:"versions-navigation-search-input",attrs:{"type":"search"},domProps:{"value":(_vm.searchInputValue)},on:{"keyup":_vm.sendSearchValue,"input":function($event){if($event.target.composing)return;_vm.searchInputValue=$event.target.value}}}):[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showSearchInput = true}}},[_vm._v("\n            "+_vm._s(_vm.search)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"versions-navigation--counter"},[_c('span',[_vm._v(_vm._s(_vm.navigatorSearch.current))]),_vm._v("/"+_vm._s(_vm.navigatorSearch.total)+"\n          ")]),_vm._v(" "),(_vm.showSearchNavigation)?[_c('div',{staticClass:"versions-navigation--arrow",on:{"click":function($event){return _vm.prevHighlight('navigatorSearch')}}},[_c('img',{attrs:{"src":require('@/assets/icons/ArrowUpNav.svg')}})]),_vm._v(" "),_c('div',{staticClass:"versions-navigation--arrow",on:{"click":function($event){return _vm.nextHighlight('navigatorSearch')}}},[_c('img',{attrs:{"src":require('@/assets/icons/ArrowDownNav.svg')}})])]:(_vm.navigatorSearch.total)?_c('button',{staticClass:"versions-navigation--show",on:{"click":_vm.showSearchNavigationHandler}},[_vm._v("\n            Pokaż\n          ")]):_vm._e()]],2):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"versions-navigation--handlers"},[(
          !_vm.showSearchInput &&
            !_vm.showSearchInputTimeout &&
            !_vm.navigatorSearch.total
        )?_c('div',{staticClass:"versions-navigation--handlersStandard desktop",on:{"click":_vm.searchPhrase}},[_c('img',{attrs:{"src":require('@/assets/icons/LensSearch.svg')}}),_vm._v(" "),_vm._m(0)]):_vm._e(),_vm._v(" "),(_vm.versions && _vm.versions.length > 1 && _vm.compareEnabled)?_c('div',{staticClass:"versions-navigation--handlersStandard desktop",on:{"click":_vm.compare}},[_c('img',{attrs:{"src":require('@/assets/icons/BookComparison.svg')}}),_vm._v(" "),_vm._m(1)]):_vm._e(),_vm._v(" "),_c(_vm.downloadLink ? `a` : `div`,{tag:"component",staticClass:"versions-navigation--handlersStandard desktop",attrs:{"href":_vm.downloadLink ? _vm.downloadLink : undefined,"target":_vm.downloadLink ? `_blank` : undefined},on:{"click":function($event){_vm.downloadLink ? undefined : _vm.$emit('download')}}},[_c('img',{attrs:{"src":require('@/assets/icons/DownloadGrey.svg')}}),_vm._v(" "),_c('div',[_vm._v("pobierz")])]),_vm._v(" "),_c('div',{staticClass:"versions-navigation--handlersMore",on:{"click":function($event){return _vm.$emit('toggle-more', true)}}},[_c('img',{attrs:{"src":require('@/assets/icons/ThreeDots.svg')}}),_vm._v(" "),_c('div',{staticClass:"desktop"},[_vm._v("\n          więcej\n        ")])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v("szukaj"),_c('br'),_vm._v("frazy")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v("porównaj"),_c('br'),_vm._v("dokumenty")])
}]

export { render, staticRenderFns }