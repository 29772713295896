import { markHighlights, replaceNewlines, checkOccurrences } from '../util';

const mapText = (text = [], highlightWords) =>
  text
    .map(
      ({ provision_crawled_text }) =>
        `<p class="paragraph-hover text-justify">${markHighlights(
          replaceNewlines(provision_crawled_text),
          highlightWords
        )}</p>`
    )
    .join('');

const mapValues = (
  key,
  highlightWords,
  highlightList,
  highlightsSearchList
) => item => {
  return {
    title:
      item[key] && item[key].length > 0
        ? item.title
        : markHighlights(item.title, highlightWords),
    paragraphs: mapText(item[key], highlightWords),
    highlights: highlightList && highlightList.includes(item.title),
    highlightsTag: highlightList && highlightList.includes(item.title),
    highlightsSearch:
      highlightsSearchList && highlightsSearchList.includes(item.title),
    modalOpen: false,
  };
};

export const prepareListInfo = keys => (data, highlights = {}) => {
  const highlightWords = highlights.full_text_keywords_list || [];

  return keys.flatMap(
    ({
      name,
      listKey,
      highlightKey,
      highlightSearchKey,
      valueKey,
      highlightTooltipName,
    }) => {
      if (!(data[listKey] && data[listKey].length)) {
        return [];
      }
      const highlightsList = highlights[highlightKey];
      const highlightsSearchList = highlights[highlightSearchKey];
      return [
        {
          name,
          values: data[listKey].map(
            mapValues(
              valueKey,
              highlightWords,
              highlightsList,
              highlightsSearchList
            )
          ),
          open: false,
          highlights: Boolean(highlightsList),
          highlightsTag: checkOccurrences(highlightsList),
          highlightsSearch: checkOccurrences(highlightsSearchList),
          highlightTooltipName,
          listKey,
        },
      ];
    }
  );
};
