var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.infos),function(info,infoIndex){return _c('base-accordion',{key:`info-${infoIndex}`,staticClass:"mb-4",attrs:{"open":info.open,"error":info.resigned},on:{"toggle":function($event){return _vm.toggleOpenInfo(infoIndex)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [(info.highlightsSearch || info.highlightsTag)?_c('highlight-icon',{attrs:{"variant":info.highlightsTag
            ? info.highlightsSearch
              ? 'both'
              : 'primary'
            : 'secondary'}},[_c('p',[_c('strong',[_vm._v("Uwaga.")]),_vm._v(" Opis zawiera słowa kluczowe z Twojego\n          monitoringu.\n        ")])]):_vm._e(),_vm._v("\n      "+_vm._s(info.name)+"\n    ")]},proxy:true}],null,true)},[_vm._v(" "),_c('info-list-show-text',{attrs:{"open":info.open,"text":info,"title":_vm.title}}),_vm._v(" "),_c('p',{staticClass:"pr-12",domProps:{"innerHTML":_vm._s(info.value)}})],1)}),_vm._v(" "),_vm._l((_vm.lists),function(list,listIndex){return _c('base-accordion',{key:`list-${listIndex}`,staticClass:"mb-4",attrs:{"open":list.open},on:{"toggle":function($event){return _vm.toggleOpenList(listIndex)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [(list.highlightsSearch || list.highlightsTag)?_c('highlight-icon',{attrs:{"variant":list.highlightsTag
            ? list.highlightsSearch
              ? 'both'
              : 'primary'
            : 'secondary'}},[_c('p',[_c('strong',[_vm._v("Uwaga.")]),_vm._v(" Zmiany dotyczą\n          "+_vm._s(_vm.getTooltipPluralWord(list) || 'ustaw objętych')+" Twoim\n          monitoringiem.\n        ")])]):_vm._e(),_vm._v("\n      "+_vm._s(list.name)+"\n    ")]},proxy:true}],null,true)},[_vm._v(" "),_vm._l((list.values),function(sublist,sublistIndex){return _c('div',{key:`sublist-${sublistIndex}`,staticClass:"sublist"},[(sublist.paragraphs.length === 0)?_c('div',{staticClass:"sublist-item sublist-item--title-only"},[(sublist.highlightsTag || sublist.highlightsSearch)?_c('highlight-icon',{attrs:{"variant":sublist.highlightsTag
              ? sublist.highlightsSearch
                ? 'both'
                : 'primary'
              : 'secondary'}},[_c('p',[_c('strong',[_vm._v("Uwaga.")]),_vm._v("\n            "+_vm._s(_vm.getTooltipSingularWord(list) || 'Ustawa jest objęta')+" Twoim\n            monitoringiem.\n          ")])]):_vm._e(),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(sublist.title)}})],1):_c('div',[_c('button',{staticClass:"sublist-item modal-open",on:{"click":function($event){sublist.modalOpen = true}}},[(sublist.highlightsTag || sublist.highlightsSearch)?_c('highlight-icon',{attrs:{"variant":sublist.highlightsTag
                ? sublist.highlightsSearch
                  ? 'both'
                  : 'primary'
                : 'secondary'}},[_c('p',[_c('strong',[_vm._v("Uwaga.")]),_vm._v("\n              "+_vm._s(_vm.getTooltipSingularWord(list) || 'Ustawa jest objęta')+" Twoim\n              monitoringiem.\n            ")])]):_vm._e(),_vm._v("\n          "+_vm._s(sublist.title)+"\n        ")],1),_vm._v(" "),_c('base-modal',{attrs:{"open":sublist.modalOpen},on:{"close":function($event){sublist.modalOpen = false}}},[_c('div',{domProps:{"innerHTML":_vm._s(sublist.paragraphs)}})])],1)])})],2)}),_vm._v(" "),(_vm.loading)?_c('base-spinner'):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }