<script>
export default {
  name: 'TextVersionsNavigation',
  props: {
    totalMonitoredHighlights: {
      type: Number,
      required: true,
    },
    totalSearchHighlights: {
      type: Number,
      default: 0,
    },
    search: {
      type: String,
      default: '',
    },
    activePhrases: {
      type: String,
      default: 'monitored',
    },
    versions: {
      type: Array,
      default: () => [],
    },
    compareEnabled: {
      type: Boolean,
      default: false,
    },
    downloadLink: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showSearchInputTimeout: null,
      showMonitoredNavigation: false,
      navigatorMonitored: {
        type: 'monitored',
        icon: 'LensMonitored.svg',
        title: 'monitorowane frazy',
        current: 1,
        total: 0,
      },
      showSearchNavigation: false,
      navigatorSearch: {
        type: 'search',
        icon: 'LensSearch.svg',
        title: 'monitorowane frazy',
        current: 1,
        total: 0,
      },
      screenWidth: 0,
      showSearchInput: false,
      searchInputValue: '',
    };
  },
  computed: {
    showMonitoredNavigationMobile: function() {
      return this.screenWidth < 1024 && this.activePhrases === 'monitored';
    },
    showSearchNavigationMobile: function() {
      return this.screenWidth < 1024 && this.activePhrases === 'search';
    },
  },
  watch: {
    showSearchInput: function(v) {
      if (v) {
        this.$nextTick(() => {
          this.$refs.searchInputRef.focus();
        });
      }
    },
    totalSearchHighlights: {
      handler(v) {
        this.showSearchNavigation = false;
        this.navigatorSearch.total = v;
        this.navigatorSearch.current = v > 0 ? 1 : 0;
        this.navigatorSearch.title = `"${this.search}"`;
        this.searchInputValue = this.search;
        if (v === 0 && this.search) {
          this.showSearchInputTimeout = setTimeout(() => {
            if (this.showSearchInputTimeout) {
              clearTimeout(this.showSearchInputTimeout);
              this.showSearchInputTimeout = null;
            }
          }, 10000);
        }
        this.$emit('input', null);
      },
      immediate: true,
    },
    totalMonitoredHighlights: {
      handler(v) {
        this.showMonitoredNavigation = false;
        this.navigatorMonitored.total = v;
        this.navigatorMonitored.current = 1;
        this.$emit('input', null);
      },
      immediate: true,
    },
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth);
    // this.$emit('input', this.navigatorMonitored);
    if (this.screenWidth < 1024) {
      this.showMonitoredNavigation = true;
      this.showSearchNavigation = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    sendSearchValue(e) {
      if (this.showSearchInputTimeout) {
        clearTimeout(this.showSearchInputTimeout);
        this.showSearchInputTimeout = null;
      }
      if (e.key === 'Enter') {
        this.$emit('search', this.searchInputValue);
        this.showSearchInput = false;
      } else if (e.key === 'Escape') {
        this.showSearchInput = false;
        this.searchInputValue = this.search;
      }
    },
    checkScreenWidth() {
      this.screenWidth =
        window.innerWidth || document.documentElement.clientWidth;
    },
    nextHighlight(field) {
      if (this[field].current > 0 && this[field].current < this[field].total) {
        this[field].current++;
      } else if (this[field].current === this[field].total) {
        this[field].current = 1;
      }
      this.$emit('input', this[field]);
    },
    prevHighlight(field) {
      if (this[field].current > 1 && this[field].current <= this[field].total) {
        this[field].current--;
      } else if (this[field].current === 1) {
        this[field].current = this[field].total;
      }
      this.$emit('input', this[field]);
    },
    showMonitoredNavigationHandler() {
      this.showMonitoredNavigation = true;
      this.$emit('input', this.navigatorMonitored);
    },
    showSearchNavigationHandler() {
      this.showSearchNavigation = true;
      this.$emit('input', this.navigatorSearch);
    },
    compare() {
      if (this.$store.getters['user/isLoggedIn']) {
        this.$emit('toggle-comparison', true);
      } else {
        this.$store.commit('openGuestModal');
      }
    },
    searchPhrase() {
      if (this.$store.getters['user/isLoggedIn']) {
        this.showSearchInput = true;
      } else {
        this.$store.commit('openGuestModal');
      }
      // if (this.totalSearchHighlights) {
      //   this.showSearchInput = true;
      // } else {
      //   this.$emit('toggle-more', true);
      // }
    },
  },
};
</script>

<template>
  <div class="versions-navigation">
    <div
      v-if="showSearchNavigationMobile || showMonitoredNavigationMobile"
      class="versions-navigation--top"
    >
      <template v-if="showSearchNavigationMobile">
        <img :src="require(`@/assets/icons/${navigatorSearch.icon}`)" />
        <div>{{ search }}</div>
      </template>
      <template
        v-else-if="showMonitoredNavigationMobile && navigatorMonitored.total"
      >
        <img :src="require(`@/assets/icons/${navigatorMonitored.icon}`)" />
        <div>monitorowane frazy</div>
      </template>
      <div class="versions-navigation--topDate">
        {{ versions[0].date }}
      </div>
    </div>
    <div class="versions-navigation--main">
      <div
        v-if="versions && versions.length > 1"
        class="versions-navigation--list"
        @click="$emit('toggle-versions', true)"
      >
        <img :src="require('@/assets/icons/FloatingBar.svg')" />
        <div>
          <div class="desktop">wersje</div>
          <div>
            <slot name="versionDate">
              {{ versions[0].date }}
            </slot>
          </div>
        </div>
      </div>
      <div class="versions-navigation--navigators">
        <div
          v-if="
            navigatorMonitored.total &&
              (screenWidth >= 1024 || showMonitoredNavigationMobile)
          "
          class="versions-navigation--navigator"
        >
          <img
            :src="
              require(`@/assets/icons/${
                navigatorMonitored.total
                  ? navigatorMonitored.icon
                  : 'LensDisabled.svg'
              }`)
            "
          />
          <div>{{ navigatorMonitored.title }}</div>
          <div
            v-if="navigatorMonitored.total"
            class="versions-navigation--counter"
          >
            <span>{{ navigatorMonitored.current }}</span
            >/{{ navigatorMonitored.total }}
          </div>
          <template v-if="showMonitoredNavigation">
            <div
              class="versions-navigation--arrow"
              @click="prevHighlight('navigatorMonitored')"
            >
              <img :src="require('@/assets/icons/ArrowUpNav.svg')" />
            </div>
            <div
              class="versions-navigation--arrow"
              @click="nextHighlight('navigatorMonitored')"
            >
              <img :src="require('@/assets/icons/ArrowDownNav.svg')" />
            </div>
          </template>
          <button
            v-else
            :class="[
              'versions-navigation--show',
              {
                'versions-navigation--show-disabled':
                  navigatorMonitored.total <= 0,
              },
            ]"
            @click="
              navigatorMonitored.total ? showMonitoredNavigationHandler() : null
            "
          >
            Pokaż
          </button>
        </div>
        <div
          v-if="
            navigatorSearch.total || showSearchInput || showSearchInputTimeout
          "
          class="versions-navigation--navigator"
        >
          <img
            :src="require(`@/assets/icons/${navigatorSearch.icon}`)"
            style="cursor: pointer"
            @click="showSearchInput = true"
          />
          <input
            v-if="showSearchInput"
            ref="searchInputRef"
            v-model="searchInputValue"
            class="versions-navigation-search-input"
            type="search"
            @keyup="sendSearchValue"
          />
          <template v-else>
            <div @click="showSearchInput = true" style="cursor: pointer">
              {{ search }}
            </div>
            <div class="versions-navigation--counter">
              <span>{{ navigatorSearch.current }}</span
              >/{{ navigatorSearch.total }}
            </div>
            <template v-if="showSearchNavigation">
              <div
                class="versions-navigation--arrow"
                @click="prevHighlight('navigatorSearch')"
              >
                <img :src="require('@/assets/icons/ArrowUpNav.svg')" />
              </div>
              <div
                class="versions-navigation--arrow"
                @click="nextHighlight('navigatorSearch')"
              >
                <img :src="require('@/assets/icons/ArrowDownNav.svg')" />
              </div>
            </template>
            <button
              v-else-if="navigatorSearch.total"
              class="versions-navigation--show"
              @click="showSearchNavigationHandler"
            >
              Pokaż
            </button>
          </template>
        </div>
      </div>
      <div class="versions-navigation--handlers">
        <div
          v-if="
            !showSearchInput &&
              !showSearchInputTimeout &&
              !navigatorSearch.total
          "
          class="versions-navigation--handlersStandard desktop"
          @click="searchPhrase"
        >
          <img :src="require('@/assets/icons/LensSearch.svg')" />
          <div>szukaj<br />frazy</div>
        </div>
        <div
          v-if="versions && versions.length > 1 && compareEnabled"
          class="versions-navigation--handlersStandard desktop"
          @click="compare"
        >
          <img :src="require('@/assets/icons/BookComparison.svg')" />
          <div>porównaj<br />dokumenty</div>
        </div>
        <component
          :is="downloadLink ? `a` : `div`"
          class="versions-navigation--handlersStandard desktop"
          :href="downloadLink ? downloadLink : undefined"
          :target="downloadLink ? `_blank` : undefined"
          @click="downloadLink ? undefined : $emit('download')"
        >
          <img :src="require('@/assets/icons/DownloadGrey.svg')" />
          <div>pobierz</div>
        </component>
        <div
          class="versions-navigation--handlersMore"
          @click="$emit('toggle-more', true)"
        >
          <img :src="require('@/assets/icons/ThreeDots.svg')" />
          <div class="desktop">
            więcej
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.versions-navigation {
  position: sticky;
  bottom: 20px;
  margin-inline: auto;
  z-index: 1;
  left: 0;
  background-color: #fff;
  border-radius: 8px;
  width: fit-content;
  user-select: none;
  box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.25);
  @include mq('desktop') {
    -webkit-box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
  &--handlersMore {
    @include mq('desktop') {
      display: none !important;
    }
  }
  &-search-input {
    outline: none !important;
    border: none !important;
    min-height: 26px;
    padding: 0 10px;
    border-radius: 3px;
  }
  &--top {
    display: flex;
    align-items: center;
    gap: 3px;
    justify-content: center;
    padding-top: 10px;
    padding-inline: 10px;
    &Date {
      color: $gray-3;
    }
    @include mq('desktop') {
      display: none;
    }
  }
  &--main {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
  }
  &--navigators {
    flex-grow: 1;
    display: flex;
    grid-gap: 10px;
    flex-direction: column;
    @include mq('desktop') {
      flex-direction: row;
    }
  }
  &--list,
  &--handlers {
    cursor: pointer;
    display: flex;
    align-items: start;
    gap: 4px;
    @include mq('desktop') {
      flex-direction: row;
      gap: 17px;
    }
    &Standard {
      color: $gray-3;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 0.95rem;
      gap: 4px;
    }
    &More {
      display: flex;
      align-items: center;
      gap: 4px;
      > div > div {
        font-size: 0.95rem;
        text-align: center;
        @include mq('desktop') {
          text-align: left;
        }
        &:nth-child(2) {
          color: $gray-3;
          display: none;
          @include mq('desktop') {
            display: unset;
          }
        }
      }
    }
  }
  &--list {
    > div > div {
      font-size: 0.95rem;
      text-align: center;
      @include mq('desktop') {
        text-align: left;
      }
      &:nth-child(2) {
        color: $gray-3;
        display: none;
        @include mq('desktop') {
          display: unset;
        }
      }
    }
  }
  &--navigator {
    background-color: $gray-6;
    min-height: 70px;
    border-radius: 8px;
    padding-inline: 10px;
    align-items: center;
    display: flex;
    gap: 8px;
    > div:nth-child(2),
    > img {
      display: none;
      @include mq('desktop') {
        display: unset;
      }
    }
    @include mq('desktop') {
      justify-content: start;
    }
    justify-content: center;
    .versions-navigation--counter {
      order: 2;
    }
    > div {
      &:nth-of-type(3) {
        order: 1;
      }
      &:nth-of-type(4) {
        order: 3;
      }
    }
  }
  &--counter {
    font-size: 0.9rem;
    background-color: white;
    min-height: 26px;
    padding: 2px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
    }
  }
  &--arrow {
    cursor: pointer;
  }
  &--show {
    cursor: pointer;
    background-color: $primary;
    color: white;
    font-weight: 600;
    letter-spacing: 0.6px;
    min-height: 26px;
    font-size: 0.9rem;
    padding: 2px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    &-disabled {
      cursor: default;
      background-color: $gray-4;
      color: $gray-1;
    }
  }
}
</style>
