export const prepareAttachments = data => {
  let issued = [];
  let draft = [];
  if (data.attachments && data.attachments.issued) {
    issued = data.attachments.issued.map(a => ({
      ...a,
      extension: a.name.split('.').pop(),
    }));
  }
  if (data.attachments && data.attachments.draft) {
    draft = data.attachments.draft.map(a => ({
      ...a,
      extension: a.name.split('.').pop(),
    }));
  }
  return {
    issued,
    draft,
  };
};
