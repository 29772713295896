<template>
  <div v-if="visible" class="mb-2">
    <base-button @click="open = true" theme="gray-5" full
      >Załączniki</base-button
    >
    <base-modal :open="open" @close="open = false">
      <div class="row mb-12">
        <div
          v-if="attachments.draft.length"
          :class="attachments.issued.length ? 'col-6' : 'col-12'"
        >
          <p>{{ labels.attachmentsDraftTitle }}</p>
          <a
            v-for="(link, linkIndex) in attachments.draft"
            :key="linkIndex"
            :href="link.link"
            class="attachment-link mb-4"
            target="_blank"
            download
          >
            <span class="attachment-link-icon">
              <DocumentIcon :extension="link.extension" />
            </span>
            {{ link.name }}
          </a>
        </div>
        <div
          v-if="attachments.issued.length"
          :class="attachments.draft.length ? 'col-6' : 'col-12'"
        >
          <p>{{ labels.attachmentsIssuedTitle }}</p>
          <a
            v-for="(link, linkIndex) in attachments.issued"
            :key="linkIndex"
            :href="link.link"
            class="attachment-link mb-4"
            target="_blank"
            download
          >
            <span class="attachment-link-icon">
              <DocumentIcon :extension="link.extension" />
            </span>
            {{ link.name }}
          </a>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
import DocumentIcon from './DocumentIcon';

export default {
  components: {
    DocumentIcon,
  },
  props: {
    attachments: Object,
    labels: Object,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    visible() {
      if (!this.attachments) {
        return false;
      }
      return this.attachments.issued.length || this.attachments.draft.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-link {
  display: inline-block;
  margin-right: 1rem;
  padding: 0.4rem 0.7rem;
  border: 3px solid $gray-5;
  border-radius: 0.4rem;
  font-size: 1rem;
  color: $gray-3;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s;

  &:hover {
    border-color: $primary;
    color: $primary;

    .attachment-link-icon {
      opacity: 1;
    }
  }

  &--gray {
    color: $gray-1;
    background-color: $gray-5;

    &:hover,
    &:focus {
      background-color: $primary;
      border-color: $primary;
      color: white;
    }
  }
}
.attachment-link-icon {
  display: inline-block;
  height: 1.24rem;
  margin-right: 0.5rem;
  line-height: 1;
  vertical-align: middle;
  opacity: 0.5;
  transition: opacity 0.15s;
}
</style>
