<template>
  <div v-if="!empty">
    <base-button
      :loading="loading"
      @click="open = true"
      theme="primary"
      bordered
      full
    >
      Pobierz dostępne dokumenty
    </base-button>
    <base-modal :open="open" @close="open = false">
      <h3 class="title mb-4 mb-desktop-16">
        <img :src="require('@/assets/icons/Download.svg')" />
        Pobierz dostępne dokumenty
      </h3>
      <div class="header row">
        <div class="col-tablet-2 tablet-desktop">Data zmiany</div>
        <div class="col-12 col-tablet-10 row row--no-gutters mb-12">
          <div class="col-8 col-tablet-5">Etap</div>
          <div class="col-4 text-right mobile">Data zmiany</div>
          <div class="col-tablet-7 tablet-desktop">Dokumenty</div>
        </div>
      </div>
      <div v-for="(item, index) in items" :key="index" class="row">
        <div class="pad-top col-tablet-2 tablet-desktop">{{ item.date }}</div>
        <div class="col-12 col-tablet-10">
          <div
            v-for="(stage, stageIndex) in item.stages"
            :key="stageIndex"
            class="row row--no-gutters mb-12"
          >
            <div class="pad-top stage-name col-8 col-tablet-5 mb-4 mb-tablet-0">
              {{ stage.name }}
            </div>
            <div class="col-4 text-right mobile stage-date">
              {{ item.date }}
            </div>
            <div class="col-12 col-tablet-7">
              <a
                v-for="(link, linkIndex) in stage.links"
                :key="linkIndex"
                :href="link.link"
                class="link mb-4"
                :class="{ 'link--gray': link.isText }"
                target="_blank"
                rel="nofollow"
                download
              >
                {{ link.text }}
                <span class="link-icon">
                  <DocumentIcon :extension="link.extension" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
import DocumentIcon from './DocumentIcon';

export default {
  components: {
    DocumentIcon,
  },
  props: {
    loading: Boolean,
    items: Array,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    empty() {
      return !this.items || this.items.length === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  gap: 0.4rem;
  font-size: 1.3rem;
  font-weight: bold;
  color: $primary;
  margin-top: 0;
}

.header {
  color: $gray-3;
  @include mqMax('tablet') {
    display: none;
  }
}

.stage-name {
  @include mqMax('tablet') {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
  }
}

.stage-date {
  @include mqMax('tablet') {
    font-size: 1rem;
  }
}

.row {
  font-size: 1.14rem;
}
.pad-top {
  @include mq('tablet') {
    padding-top: 0.7rem;
  }
}
.link {
  display: inline-block;
  margin-right: 1rem;
  padding: 0.4rem 0.7rem;
  border: 3px solid $gray-5;
  border-radius: 0.4rem;
  font-size: 1rem;
  color: $gray-3;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s;

  &:hover {
    border-color: $primary;
    color: $primary;

    .link-icon {
      opacity: 1;
    }
  }

  &--gray {
    color: $gray-1;
    background-color: $gray-5;

    &:hover,
    &:focus {
      background-color: $primary;
      border-color: $primary;
      color: white;
    }
  }
}
.link-icon {
  display: inline-block;
  height: 1.24rem;
  margin-left: 1rem;
  line-height: 1;
  vertical-align: middle;
  opacity: 0.5;
  transition: opacity 0.15s;
}
</style>
