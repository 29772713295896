<template>
  <div :class="['item-wrap']">
    <router-link
      v-if="!listItem"
      :to="{
        name: config.routeList,
      }"
      class="item__back mb-16"
      >przejdź do aktualności</router-link
    >

    <div
      v-if="!showRemoved"
      ref="item"
      @touchstart="onTouchStart"
      @touchmove="onTouchMove"
      @touchend="onTouchEnd"
      class="item"
      :class="{
        'item--narrow': narrow,
        'item--open': open,
        'item--single': !listItem,
      }"
    >
      <div v-if="listItem ? open && loading : loading" class="item__loading">
        <base-spinner large />
      </div>
      <div v-else>
        <div class="item__header">
          <button
            v-if="listItem && !open"
            @mousedown="event => $emit('toggleOpen', event)"
            @mouseup="event => $emit('toggleOpen', event)"
            class="item__toggle item__toggle--closed"
          >
            <div class="item__toggle-inner">
              <div class="item__closed-title">
                <span class="item__closed-title-text">{{ item.title }}</span>
                <div class="item__closed-date">
                  <span class="mobile">ostatnia zmiana:</span>
                  {{ item.date.toLocaleDateString('pl') }}
                </div>
              </div>
              <slot />
            </div>
          </button>
          <div v-else class="item__toggle">
            <div class="item__toggle-inner">
              <span class="item__title mb-4 mb-tablet-0">
                <highlight-icon
                  v-if="item.titleHighlights || item.titleSearchHighlights"
                  :variant="
                    item.titleHighlights
                      ? item.titleSearchHighlights
                        ? 'both'
                        : 'primary'
                      : 'secondary'
                  "
                >
                  <p>
                    <strong>Uwaga.</strong> Informacja zawiera słowa kluczowe z
                    Twojego monitoringu.
                  </p>
                </highlight-icon>
                {{ item.title }}
              </span>
              <span v-if="item.status === 'F'" class="item__status">
                <span class="status-icon">!</span>
                obowiązuje
              </span>
              <span
                v-else-if="item.status === 'R'"
                class="item__status item__status--error"
              >
                <span class="status-icon status-icon--error">!</span>
                projekt archiwalny
              </span>
              <button class="item__close">
                <span
                  class="item__close-icon"
                  @mousedown="event => $emit('toggleOpen', event)"
                  @mouseup="event => $emit('toggleOpen', event)"
                />
              </button>
            </div>
          </div>

          <button
            v-if="listItem && open"
            @click="copyLink(item.slug)"
            class="item__link"
          >
            <template v-if="canShare">
              <base-icon-share />
            </template>
            <template v-else>
              <base-tooltip
                type="secondary"
                position="left"
                position-tablet="top"
                nowrap
                small
              >
                <template #text>skopiuj link</template>
                <base-icon-link class="item__link-icon" />
              </base-tooltip>
            </template>
          </button>
          <base-button-favorite
            :itemId="item ? item.id : null"
            :itemType="config.bookmark.type"
            class="item__favorite"
            :disabled="!isBookmarked"
            :active="isBeloved"
          />
          <base-button-bookmark
            :active="isBookmarked"
            @click="toggleBookmark"
            class="item__bookmark"
          />
        </div>

        <div v-if="!listItem || open">
          <div class="item__main row row--no-gutters">
            <div class="col-12 mb-8">
              <item-modules
                @update:projectModules="projectModulesUpdate"
                :item="item"
                :isBookmarked="isBookmarked"
              />
            </div>
            <div class="col-12 col-tablet-7">
              <div
                class="row row--between row--no-gutters row--vcenter mb-8 mb-tablet-4"
              >
                <transition name="fade" mode="out-in">
                  <h3 :key="item.id" class="item__main-subtitle mb-0">
                    {{ item.initiativeType }}
                  </h3>
                </transition>
                <portal to="siblings" :disabled="$util.mq('tablet')">
                  <siblings-nav
                    v-if="hasSiblings"
                    :loading="loadingSiblings"
                    :siblings="siblingSlugs"
                    :current="item.slug"
                    @change="changeSibling"
                    class="mb-8 mb-tablet-0"
                  />
                </portal>
              </div>
              <div class="item__info mb-12 mb-tablet-4">
                <transition
                  :name="`slide-${siblingSlideAnimDirection}`"
                  mode="out-in"
                >
                  <div :key="item.id">
                    <ul class="item__summary mb-12">
                      <li
                        v-for="summaryItem in item.summary"
                        :key="summaryItem.type"
                        class="item__summary-item mb-2 row row--no-gutters"
                      >
                        <div class="col-6">
                          <highlight-icon v-if="summaryItem.highlight">
                            <p>
                              <strong>Uwaga.</strong> Informacja zawiera słowa
                              kluczowe z Twojego monitoringu.
                            </p>
                          </highlight-icon>
                          {{ summaryItem.type }}
                        </div>
                        <div class="item__summary-value col-6">
                          <a
                            v-if="typeof summaryItem.value === 'object'"
                            :href="summaryItem.value.link"
                            class="item__summary-link"
                          >
                            {{ summaryItem.value.number }}
                            <base-icon-link class="item__summary-link-icon" />
                          </a>
                          <span v-else v-html="summaryItem.value" />
                        </div>
                      </li>
                    </ul>

                    <div v-if="item.links" class="item__links row mb-12">
                      <div
                        v-if="item.links.project.length"
                        :class="item.links.done.length ? 'col-6' : 'col-12'"
                      >
                        <p>{{ config.labels.linksTitle }}</p>
                        <logo-links :links="item.links.project" />
                      </div>
                      <div
                        v-if="item.links.done.length"
                        :class="item.links.project.length ? 'col-6' : 'col-12'"
                      >
                        <p>{{ config.labels.linksDoneTitle }}</p>
                        <logo-links :links="item.links.done" />
                      </div>
                    </div>
                  </div>
                </transition>

                <portal-target name="siblings" />

                <transition
                  :name="`slide-${siblingSlideAnimDirection}`"
                  mode="out-in"
                >
                  <div :key="item.id">
                    <div class="row row--no-gutter">
                      <div class="col-12 col-tablet-9 mb-2">
                        <text-versions
                          v-if="highlightsFetched"
                          :api="config.api.textVersions"
                          :item="item"
                          :highlights="highlights.full_text_keywords_list"
                          :highlightSearch="
                            highlights.full_text_keywords_search_list
                              ? highlights.full_text_keywords_search_list[0]
                              : ''
                          "
                        />
                        <base-button v-else theme="primary" loading full>
                          Najnowszy tekst projektu
                        </base-button>
                      </div>
                      <div class="col-12 col-tablet-9">
                        <item-attachments
                          :attachments="item.attachments"
                          :labels="config.labels"
                        />
                      </div>
                      <div class="col-12 col-tablet-9">
                        <text-links
                          v-if="!loadingAdditional"
                          :loading="loadingAdditional"
                          :items="item.textLinks"
                        />
                        <base-button
                          v-else
                          loading
                          theme="primary"
                          bordered
                          full
                        >
                          Pobierz dostępne dokumenty
                        </base-button>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>

              <monitored
                :api="`v0/${config.api.monitored}`"
                :item-id="item.id"
                :highlights="highlights.monitored"
                @open="guestPrompt"
                class="mb-12 mb-tablet-0"
              />
            </div>
            <transition name="fade" mode="out-in">
              <div
                :key="item.id"
                v-if="item.timeline && item.timeline.length > 0"
                class="col-12 col-tablet-5 item__timeline"
              >
                <h3 class="item__main-subtitle mb-4">Harmonogram projektu</h3>

                <timeline :timeline="item.timeline" />
              </div>
            </transition>
          </div>

          <div class="item__more row">
            <div class="col-12 col-tablet-7">
              <transition name="fade" mode="out-in">
                <info-lists
                  :key="item.id"
                  :infos="item.info"
                  :lists="item.infoLists"
                  :loading="loadingAdditional"
                  :title="item.title"
                />
              </transition>
            </div>
            <div class="item__more-bookmark tablet-desktop col-5 mb-4">
              <base-button-favorite
                v-if="isBookmarked"
                :itemId="item ? item.id : null"
                :itemType="config.bookmark.type"
                full
                :disabled="!isBookmarked"
                :active="isBeloved"
              />
              <base-button-bookmark
                :active="isBookmarked"
                @click="toggleBookmark"
                full
              />
            </div>
          </div>

          <div class="item__more-bookmark mobile">
            <base-button-favorite
              :itemId="item ? item.id : null"
              :itemType="config.bookmark.type"
              full
              nolabel
              :disabled="!isBookmarked"
              v-if="isBookmarked"
              :active="isBeloved"
            />
            <base-button-bookmark
              :active="isBookmarked"
              @click="toggleBookmark"
              full
              nolabel
            />
          </div>
        </div>
      </div>
    </div>

    <!--    <item-remove-background v-if="listItem && !showRemoved" />-->
    <item-swipe
      v-if="listItem && !showRemoved"
      :swipe="translateX"
      :bookmarked="isBookmarked"
      :beloved="isBeloved"
      :itemId="listItem ? listItem.id : null"
    />
    <item-removed v-show="showRemoved" @restore="restore" />
    <base-modal
      :open="showCopySuccessModal"
      @close="showCopySuccessModal = false"
    >
      <div class="mb-8 heading heading--small">
        Skopiowałeś link do schowka
      </div>

      <div style="display: flex; justify-content: end; grid-gap: 10px">
        <a :href="copiedURL" target="_blank">
          <base-button theme="primary" @click="showCopySuccessModal = false">
            Otwórz w nowej zakładce
          </base-button>
        </a>
        <base-button theme="gray-3-plain" @click="showCopySuccessModal = false">
          Wróć
        </base-button>
      </div>
    </base-modal>
  </div>
</template>

<script>
import Timeline from './components/Timeline';
import LogoLinks from './components/LogoLinks/LogoLinks';
import Monitored from './components/Monitored/Monitored';
import TextVersions from './components/TextVersions';
import TextLinks from './components/TextLinks';
import InfoLists from './components/InfoLists';
import HighlightIcon from './components/HighlightIcon';
import ItemRemoved from './components/ItemRemoved';
// import ItemRemoveBackground from './components/ItemRemoveBackground';
import ItemSwipe from '@/components/Item/components/ItemSwipe.vue';
import ItemAttachments from './components/ItemAttachments';
import SiblingsNav from './components/SiblingsNav';
import ItemModules from '@/components/Item/components/ItemModules.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    LogoLinks,
    Monitored,
    Timeline,
    TextVersions,
    TextLinks,
    InfoLists,
    HighlightIcon,
    ItemRemoved,
    ItemSwipe,
    // ItemRemoveBackground,
    ItemAttachments,
    SiblingsNav,
    ItemModules,
  },
  props: {
    config: Object,
    open: Boolean,
    searchHighlights: {
      query: String,
      keywords: Array,
    },
    listItem: Object,
    narrow: Boolean,
  },
  metaInfo() {
    if (!this.listItem) {
      return {
        title: this.loading ? 'Ładuję...' : this.item.title,
      };
    }
    if (!this.open || this.isLoading) {
      return;
    }
    return {
      title: this.item.title,
    };
  },
  data() {
    return {
      showCopySuccessModal: false,
      copiedURL: null,
      projectModules: null,
      removed: false,
      loading: false,
      loadingAdditional: true,
      detailLoaded: false,
      highlights: {},
      highlightsFetched: false,
      item: this.listItem,
      siblings: {},
      hasSiblings: false,
      loadingSiblings: false,
      dragStartX: 0,
      dragCurrentX: 0,
      dragging: false,
      siblingSlideAnimDirection: 'right',
      translateX: 0,
      currentWindowWidth: 0,
      descriptions: null,
    };
  },
  computed: {
    ...mapGetters('list', {
      belovedProjects: 'beloved',
      hatedProjects: 'hated',
      favoriteProjects: 'favorite',
    }),
    canShare() {
      const mobileRegex = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
      const subMobileRegex = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i;

      if (
        mobileRegex.test(navigator.userAgent) ||
        subMobileRegex.test(navigator.userAgent.substr(0, 4))
      ) {
        return !!navigator.share;
      }

      return !!navigator.share && this.currentWindowWidth <= 766;
    },
    isHated() {
      return this.hatedProjects.includes(
        this.listItem ? this.listItem.id.toString() : this.item.id.toString()
      );
    },
    isBeloved() {
      return (
        this.belovedProjects.includes(
          this.listItem ? this.listItem.id.toString() : this.item.id.toString()
        ) && this.isBookmarked
      );
    },
    isFavorite() {
      if (!this.listItem || !this.item) {
        return false;
      }
      return !!this.favoriteProjects.find(item =>
        item.id === this.listItem ? this.listItem.id : this.item.id
      );
    },
    siblingSlugs() {
      return Object.keys(this.siblings);
    },
    showRemoved() {
      return this.removed && !this.open;
    },
    isBookmarked() {
      return (
        this.$store.getters['bookmarks/isBookmark']({
          type: this.config.bookmark.type,
          id: this.item.id,
        }) &&
        !this.isHated &&
        (this.isFavorite ||
          this.projectModules === null ||
          this.projectModules.length > 0 ||
          this.projectModules > 0)
      );
    },
    isSearch() {
      return this.searchHighlights !== undefined;
    },
    ...mapGetters('list', {
      favoriteHated: 'favoriteHated',
    }),
  },
  created() {
    if (!this.listItem) {
      this.$store.dispatch(`bookmarks/${this.config.bookmark.fetch}`);
      this.fetchData();
    }
    this.getCurrentWidowWidth();
    window.addEventListener('resize', this.getCurrentWidowWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.getCurrentWidowWidth);
  },
  watch: {
    $route: 'fetchData',
    async open() {
      if (this.open && !this.detailLoaded) {
        await this.fetchData();
      } else if (this.open && this.detailLoaded) {
        this.$emit('loaded');
      }
    },
  },
  methods: {
    ...mapActions('list', {
      setBeloved: 'setBeloved',
      unsetBeloved: 'unsetBeloved',
    }),
    getCurrentWidowWidth() {
      this.currentWindowWidth = window.innerWidth;
    },
    projectModulesUpdate(modules) {
      this.projectModules = modules;
    },
    guestPrompt() {
      if (!this.$store.getters['user/isLoggedIn']) {
        this.$store.commit('openGuestModal');
      }
    },
    async fetchData() {
      this.loading = true;
      try {
        const slug = this.listItem ? this.item.slug : this.$route.params.slug;
        const siblings = await this.fetchInitialItem(slug);
        if (siblings && siblings.length > 0) {
          this.fetchSiblings(this.item, siblings);
          this.hasSiblings = true;
        }
        this.detailLoaded = true;
      } catch (err) {
        if (err.response && err.response.status === 404) {
          this.$router.push({
            name: this.config.route404,
          });
        } else {
          console.log('problem fetching item', err);
        }
      }
    },
    async fetchInitialItem(slug, isSibling = false) {
      const data = await this.fetchItem(slug);
      const prepareItemFn = isSibling
        ? this.config.prepareData.prepareSibling
        : this.config.prepareData.prepareItem;
      this.item = {
        ...this.item,
        ...prepareItemFn(data, this.descriptions, {}, this.isSearch),
      };
      this.loading = false;
      this.$emit('loaded');
      const highlightsPromise = await this.fetchHighlights(this.item.id);
      this.updateHighlights(data, highlightsPromise, prepareItemFn);
      const additionalPromise = this.fetchAdditional(isSibling);
      this.updateAdditionalHighlights(additionalPromise, highlightsPromise);
      return data.merged_compileds_slug_list;
    },
    async fetchSiblings(currentItem, list) {
      this.loadingSiblings = true;
      const requests = list.map(slug => this.fetchItem(slug));
      const responses = await Promise.all(requests);
      const additionalRequests = responses.map(data =>
        this.fetchSiblingAdditional(data)
      );
      const additionalResponses = await Promise.all(additionalRequests);
      const siblings = [currentItem, ...additionalResponses];
      this.siblings = Object.fromEntries(siblings.map(s => [s.slug, s]));
      this.loadingSiblings = false;
    },
    async fetchSiblingAdditional(data) {
      const highlights = await this.fetchHighlights(data.pk);
      const additionalRaw = await this.$api.get(
        `v1/${this.config.api.additional}/${data.pk}/`
      );
      const item = {
        ...this.config.prepareData.prepareSibling(
          data,
          highlights,
          this.isSearch,
          this.descriptions
        ),
        ...this.config.prepareData.prepareItemAdditional(
          additionalRaw.data,
          [],
          highlights
        ),
      };
      return item;
    },
    async fetchDescriptions(id) {
      try {
        const response = await this.$api.get(
          `${this.config.api.descriptions}/${id}/`
        );
        return response.data;
      } catch (err) {
        if (err.response && err.response.status === 404) {
          this.$router.push({
            name: this.config.route404,
          });
        } else {
          console.log('problem fetching item', err);
        }
        return err;
      }
    },
    async changeSibling({ slug, direction }) {
      this.siblingSlideAnimDirection = direction;
      this.item = {
        ...this.item,
        ...this.siblings[slug],
      };
    },
    async fetchItem(slug) {
      const bodyResponse = await this.$api.get(
        `v1/${this.config.api.body}/${slug}/`
      );
      this.descriptions = await this.fetchDescriptions(bodyResponse.data.pk);
      return bodyResponse.data;
    },
    async updateHighlights(data, highlightsPromise, prepareFn) {
      const highlights = await highlightsPromise;
      this.highlights = highlights;
      this.highlightsFetched = true;
      this.item = {
        ...this.item,
        ...prepareFn(data, this.descriptions, highlights, this.isSearch),
      };
    },
    async updateAdditionalHighlights(additionalPromise, highlightsPromise) {
      const [
        { additional, existingInfoLists },
        highlights,
      ] = await Promise.all([additionalPromise, highlightsPromise]);
      this.item = {
        ...this.item,
        ...this.config.prepareData.prepareItemAdditional(
          additional,
          existingInfoLists,
          highlights
        ),
      };
    },
    async fetchHighlights(id) {
      let highlights = {};
      if (!this.$store.getters['user/isLoggedIn']) {
        return highlights;
      }
      if (this.isSearch) {
        const { data } = await this.$api.get(
          this.searchHighlights.query.replace(':id', id)
        );
        highlights = data;
        highlights.description_keywords_search_list = this.searchHighlights.keywords;
        highlights.full_text_keywords_search_list = this.searchHighlights.keywords;
        // return {
        //   ...data,
        //   description_keywords_list: this.searchHighlights.keywords,
        //   full_text_keywords_list: this.searchHighlights.keywords,
        // };
      }
      const highlightsResponse = await this.$api.get(
        `v0/${this.config.api.highlights}/${id}`
      );

      highlights.monitored = highlightsResponse.data.full_text_keywords_list;
      highlights = { ...highlights, ...highlightsResponse.data };

      return highlights;
      // return {
      //   ...highlightsResponse.data,
      //   monitored: highlightsResponse.data.full_text_keywords_list,
      // };
    },
    async fetchAdditional(isSibling) {
      this.loadingAdditional = true;
      const { data } = await this.$api.get(
        `v1/${this.config.api.additional}/${this.item.id}/`
      );
      const existingInfoLists = isSibling ? [] : this.item.infoLists;
      this.item = {
        ...this.item,
        ...this.config.prepareData.prepareItemAdditional(
          data,
          existingInfoLists,
          {}
        ),
      };
      this.loadingAdditional = false;
      return { additional: data, existingInfoLists };
    },
    toggleBookmark() {
      if (!this.$store.getters['user/isLoggedIn']) {
        this.guestPrompt();
      }
      if (
        this.$route.name.includes('my-monitoring') &&
        !this.$util.mq('tablet')
      ) {
        this.removed = this.isBookmarked;
      }
      this.$store.dispatch(`bookmarks/${this.config.bookmark.toggle}`, {
        id: this.item.id,
        method:
          !this.isBookmarked &&
          this.projectModules &&
          this.projectModules.length === 0
            ? 'put'
            : null,
      });
    },
    async copyLink(slug) {
      const route = this.$router.resolve({
        name: this.config.routeName,
        params: { slug },
      });
      const link = window.location.origin + route.href;
      if (this.canShare) {
        await navigator.share({
          title: this.item.title,
          url: link,
        });
      } else {
        await this.$util.copyToClipboard(link);
        // alert('Link skopiowany!');
        this.showCopySuccessModal = true;
      }
      this.copiedURL = link;
    },
    restore() {
      this.toggleBookmark();
    },
    onTouchStart(evt) {
      if (this.$util.mq('tablet') || !this.listItem || this.open) {
        return;
      }
      this.dragStartX = evt.touches[0].pageX;
      this.dragCurrentX = this.dragStartX;
      this.dragging = true;
      requestAnimationFrame(this.update);
    },
    onTouchMove(evt) {
      const swipeDelay = 50;
      if (
        !this.dragging ||
        (evt.touches[0].pageX - this.dragStartX < swipeDelay &&
          evt.touches[0].pageX - this.dragStartX > -swipeDelay)
      ) {
        this.dragCurrentX = this.dragStartX;
        return;
      }
      this.dragCurrentX = evt.touches[0].pageX;
    },
    onTouchEnd(evt) {
      if (!this.dragging) {
        return;
      }
      this.dragging = false;
      // const translateX = Math.min(0, this.dragCurrentX - this.dragStartX);
      const translateX = this.dragCurrentX - this.dragStartX;
      this.$refs.item.style.transform = '';

      if (this.isBeloved && translateX < -80) {
        this.unsetBeloved({ id: this.listItem.id });
      } else if (this.isBookmarked && translateX > 80) {
        this.setBeloved({ id: this.listItem.id });
      } else if (this.isBookmarked && translateX < -80) {
        this.toggleBookmark();
      } else if (translateX > 80) {
        this.toggleBookmark();
      }
      this.translateX = 0;
    },
    update() {
      if (!this.dragging) {
        return;
      }
      requestAnimationFrame(this.update);
      // const translateX = Math.max(
      //   -window.innerWidth / 2,
      //   Math.min(0, this.dragCurrentX - this.dragStartX)
      // );
      const max = 120;
      let translateX = this.dragCurrentX - this.dragStartX;
      if (
        (!this.isBookmarked && translateX < 0) ||
        (this.isBeloved && translateX > 0)
      ) {
        translateX = 0;
      } else if (translateX < -max) {
        translateX = -max;
      } else if (translateX > max) {
        translateX = max;
      }
      this.$refs.item.style.transform = `translateX(${translateX}px)`;
      this.translateX = translateX;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './Item';
</style>
