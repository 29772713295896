<template>
  <div class="logo-links">
    <logo-link
      v-for="link in links"
      :key="link.name"
      :link="link"
      class="logo-links__link"
    />
  </div>
</template>

<script>
import LogoLink from './LogoLink';

export default {
  components: {
    LogoLink,
  },
  props: {
    links: Array,
  },
};
</script>

<style lang="scss" scoped>
.logo-links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include mq('phone') {
    display: block;
  }
}
.logo-links__link {
  display: inline-block;
  max-width: 50%;
  padding-right: 1.5rem;

  @include mq('phone') {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}
</style>
