<template>
  <div
    :class="['item__swipe mobile', {
      'item__swipe--active': swipe !== 0,
      'item__swipe--remove': swipe < 0,
      'item__swipe--add': swipe > 0,
    }]"
  >
    <svg v-if="!beloved && bookmarked && swipe >= 0" width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.2913 2.61183C19.7805 2.10083 19.1741 1.69547 18.5066 1.41891C17.8392 1.14235 17.1238 1 16.4013 1C15.6788 1 14.9634 1.14235 14.2959 1.41891C13.6285 1.69547 13.022 2.10083 12.5113 2.61183L11.4513 3.67183L10.3913 2.61183C9.3596 1.58013 7.96032 1.00053 6.50129 1.00053C5.04226 1.00053 3.64298 1.58013 2.61129 2.61183C1.5796 3.64352 1 5.04279 1 6.50183C1 7.96086 1.5796 9.36013 2.61129 10.3918L3.67129 11.4518L11.4513 19.2318L19.2313 11.4518L20.2913 10.3918C20.8023 9.88107 21.2076 9.27464 21.4842 8.60718C21.7608 7.93972 21.9031 7.22431 21.9031 6.50183C21.9031 5.77934 21.7608 5.06393 21.4842 4.39647C21.2076 3.72901 20.8023 3.12258 20.2913 2.61183Z" stroke="#E9E9E9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-else-if="!bookmarked && swipe >= 0" width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1H13V21L7 15.8207L1 21V1Z" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
    </svg>
    <svg v-else-if="beloved && swipe < 0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4267 4.99628C22.3892 5.03763 22.3497 5.07777 22.3081 5.11655L20.9322 6.40053C20.9969 6.52315 21.0559 6.64891 21.1092 6.77743C21.3355 7.32353 21.4519 7.90887 21.4519 8.49999C21.4519 9.09112 21.3355 9.67645 21.1092 10.2226C20.8829 10.7687 20.5511 11.265 20.133 11.6829L12.0001 19.8158L9.37214 17.1878L7.90903 18.5531L11.293 21.9371C11.6835 22.3276 12.3167 22.3276 12.7072 21.9371L21.5472 13.0971C22.1511 12.4935 22.6301 11.7769 22.9569 10.9881C23.2837 10.1993 23.4519 9.35384 23.4519 8.49999C23.4519 7.64614 23.2837 6.80066 22.9569 6.01185C22.8101 5.65759 22.6325 5.31781 22.4267 4.99628ZM5.07891 15.723L2.45301 13.0971C1.23378 11.8779 0.548828 10.2242 0.548828 8.49999C0.548828 6.77574 1.23378 5.12212 2.45301 3.90289C3.67224 2.68366 5.32587 1.9987 7.05012 1.9987C8.77437 1.9987 10.428 2.68366 11.6472 3.90289L12.0001 4.25578L12.3528 3.90305C12.9564 3.29923 13.6732 2.82006 14.462 2.49324C15.2508 2.1664 16.0963 1.99817 16.9501 1.99817C17.7583 1.99817 18.5589 2.14888 19.3112 2.44214L17.594 4.04448C17.3813 4.01371 17.1661 3.99817 16.9501 3.99817C16.359 3.99817 15.7737 4.11463 15.2276 4.34091C14.6815 4.56719 14.1853 4.89885 13.7674 5.31694L12.7072 6.3771C12.3167 6.76763 11.6835 6.76763 11.293 6.3771L10.233 5.3171C9.38886 4.47295 8.24394 3.9987 7.05012 3.9987C5.8563 3.9987 4.71138 4.47295 3.86723 5.3171C3.02307 6.16126 2.54883 7.30618 2.54883 8.49999C2.54883 9.69381 3.02307 10.8387 3.86723 11.6829L6.54202 14.3577L5.07891 15.723Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.859 1.89086C23.2369 2.29363 23.2167 2.92648 22.8139 3.30436L5.36244 19.6774C4.95967 20.0553 4.32683 20.0351 3.94894 19.6324C3.57106 19.2296 3.59124 18.5968 3.99401 18.2189L21.4455 1.8458C21.8483 1.46791 22.4811 1.48809 22.859 1.89086Z" fill="currentColor"/>
    </svg>
    <svg v-else-if="bookmarked && swipe < 0" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2.57568H18V4.20157V8.46831V22.5757L12 17.3964L6 22.5757V18.1164V13.7081V2.57568Z" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
      <path d="M2 19.267L19.887 2.57568" stroke="currentBackgroundColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.95892 18.3731L20.4104 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <div v-html="label"></div>
  </div>
</template>

<script>
export default {
  name: 'ItemSwipe',
  props: {
    swipe: {
      type: Number,
      required: true,
    },
    bookmarked: {
      type: Boolean,
    },
    beloved: {
      type: Boolean,
    },
    itemId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {}
  },
  computed: {
    label() {
      if (this.swipe <= 0) {
        if (this.beloved) {
          return 'usuń projekt z&nbsp;ulubionych';
        } else {
          return 'usuń projekt z&nbsp;Twojego monitoringu';
        }
      } else if (this.swipe > 0) {
        if (this.bookmarked) {
          return 'dodaj projekt do&nbsp;ulubionych';
        } else {
          return 'dodaj projekt do&nbsp;Twojego monitoringu';
        }
      }
      return '';
    }
  },
}
</script>

<style scoped lang="scss">
.item__swipe {
  svg {
    margin-bottom: 10px;
  }
  &--remove {
    background-color: $error;
    right: 1px;
    border-radius: 0 0.4rem 0.4rem 0;
    &::before {
      right: 99%;
      background-color: $error;
    }
  }
  &--add {
    background-color: $secondary;
    left: 1px;
    border-radius: 0.4rem 0 0 0.4rem;
    &::before {
      left: 99%;
      background-color: $secondary;
    }
  }
  &--active {
    opacity: 1;
  }
  color: white;
  text-align: center;
  align-items: center;
  position: absolute;
  top: 1px;
  bottom: 1px;
  width: 7.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 1.5rem 1rem 1.7rem;
  font-size: 0.8rem;
  font-weight: normal;
  opacity: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50vw;
  }
}
</style>
