<script>
/* eslint-disable */
import TextVersionsNavigation from '@/components/Item/components/TextVersionsNavigation.vue';
import { markHighlights } from '@/util/markHighlights';
import { setVersions } from '@/util/item/prepareInfo';
import TextVersionsComparison from '@/components/Item/components/TextVersionsComparison.vue';

export default {
  name: 'InfoListShowText',
  components: { TextVersionsComparison, TextVersionsNavigation },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      expanded: true,
      modal: false,
      versionsOpen: false,
      moreOpen: false,
      highlightsCount: 0,
      highlightSearchCount: 0,
      list: [],
      currentPhrases: 'monitored',
      navigator: null,
      displayNavigation: true,
      currentText: null,
      highlightsCountsShowAll: false,
      sortHighlightsBy: 'amount',
      sortHighlightsDirection: 'desc',
      refreshTopContainer: false,
      highlightSearchInput: false,
      highlightSearchTyped: '',
      highlightWords: [],
      textPrepared: null,
      comparisonMode: false,
    };
  },
  computed: {
    allowCompare() {
      return (
        this.textPrepared &&
        this.textPrepared.versions &&
        this.textPrepared.versions.length > 1 &&
        ['lex', 'regulations', 'healthfund', 'european_union_acts'].includes(
          this.$store.state.list.api.list
        )
      );
    },
    highlightSearchComputed() {
      return this.highlightSearchTyped || this.highlightSearch;
    },
  },
  watch: {
    comparisonMode: function(v) {
      if (v) {
        this.$refs.baseModalRef.disableScroll();
      } else {
        this.$refs.baseModalRef.enableScroll();
      }
    },
    modal: {
      handler: function(v) {
        if (!v) {
          this.highlightsCount = 0;
        } else {
          this.highlightSearchCount = this.currentText.highlightSearchCounts;
          this.highlightsCount = this.currentText.highlightsCount;
          if (this.currentText.highlightSearch) {
            this.highlightSearchTyped = this.currentText.highlightSearch;
            this.currentPhrases = 'search';
          }
          this.setHighlightsWords();
        }
      },
      immediate: true,
    },
    open: {
      handler: function(v) {
        this.highlightsCountsShowAll = false;
        if (!v) {
          return;
        }
        this.expanded = true;
        setTimeout(() => {
          this.expanded = false;
        }, 2000);
      },
      immediate: true,
    },
    navigator: {
      handler(v) {
        if (!v) {
          return;
        }
        const highlights = this.$refs.textContent.querySelectorAll(
          `[data-v-highlight-type="${v.type}"]`
        );
        highlights.forEach((element, index) => {
          element.classList.remove('highlight--active');
          // element.setAttribute('data-v-highlight-index', index);
          element.addEventListener('click', () => {
            this.navigator.current = index + 1;
          });
          if (
            element.getAttribute('data-v-highlight-type') === v.type &&
            index + 1 === v.current
          ) {
            element.classList.add('highlight--active');
            this.$nextTick(() => {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            });
          }
        });
      },
      deep: true,
    },
  },
  created() {
    // if (this.highlightSearch) {
    //   this.highlightSearchTyped = this.highlightSearch;
    //   this.currentPhrases = 'search';
    // }
  },
  mounted() {},
  methods: {
    closeModal() {
      if (
        document.activeElement &&
        document.activeElement.tagName === 'INPUT'
      ) {
        return;
      }
      if (this.comparisonMode) {
        this.comparisonMode = false;
      } else {
        this.modal = false;
      }
    },
    downloadVersion() {
      window.open(this.currentText.link, '_blank');
    },
    showTextToggle() {
      this.textPrepared = JSON.parse(JSON.stringify(this.text));
      this.textPrepared.versions = setVersions(
        this.textPrepared.versionsRaw,
        this.textPrepared.highlightWordsRaw,
        this.textPrepared.highlightSearchRaw
      );
      this.currentText = this.textPrepared.versions[0];
      this.modal = true;
    },
    openHighlightSearchInput() {
      if (this.$store.getters['user/isLoggedIn']) {
        this.highlightSearchInput = true;
        setTimeout(() => {
          this.$refs.highlightSearchInputRef.$el.querySelector('input').focus();
        }, 140);
      } else {
        this.modal = false;
        setTimeout(() => {
          this.$store.commit('openGuestModal', true);
        }, 300);
      }
    },
    setHighlightsWords() {
      if (!this.currentText) {
        return [];
      }
      const words = JSON.parse(JSON.stringify(this.currentText.highlightWords));
      if (!words) {
        return [];
      }
      words.sort((a, b) => {
        if (this.sortHighlightsBy === 'amount') {
          return this.sortHighlightsDirection === 'asc'
            ? a.count - b.count
            : b.count - a.count;
        } else if (this.sortHighlightsBy === 'alphabet') {
          return this.sortHighlightsDirection === 'asc'
            ? b.word.localeCompare(a.word)
            : a.word.localeCompare(b.word);
        }
      });
      this.highlightWords = this.currentText
        ? words.splice(
            0,
            !this.highlightsCountsShowAll
              ? 4
              : this.currentText.highlightWords.length
          )
        : [];

      this.refreshTopContainer = true;
      this.$nextTick(() => {
        this.refreshTopContainer = false;
      });
    },
    applyNewSearch() {
      this.textPrepared = JSON.parse(JSON.stringify(this.text));
      this.textPrepared.versions = setVersions(
        this.textPrepared.versionsRaw,
        this.textPrepared.highlightWordsRaw,
        this.highlightSearchTyped
      );
      this.recalculateHighlights();
    },
    unselectAllHighlights() {
      this.currentText.highlightWords = this.currentText.highlightWords.map(
        item => ({
          ...item,
          active: false,
        })
      );
      this.recalculateHighlights();
    },
    selectAllHighlights() {
      this.currentText.highlightWords = this.currentText.highlightWords.map(
        item => ({
          ...item,
          active: true,
        })
      );
      this.recalculateHighlights();
    },
    toggleHighlightWord(word) {
      if (!this.currentText) {
        return;
      }
      const index = this.currentText.highlightWords.findIndex(
        item => item.word === word
      );

      if (index >= 0) {
        this.currentText.highlightWords[index].active = !this.currentText
          .highlightWords[index].active;
        this.recalculateHighlights();
      }
    },
    recalculateHighlights() {
      const result = markHighlights(
        this.currentText.originalContent,
        this.currentText.highlightWords
          .map(item => (item.active ? item.word : null))
          .filter(word => word !== null),
        this.highlightSearchTyped,
        true
      );
      this.currentText.content = result.out ? result.out : result;
      this.currentText.highlightsCount = this.currentText.highlightWords.reduce(
        (sum, word) => (word.active ? sum + word.count : sum),
        0
      );
      this.highlightSearchCount = result.matchHighlightSearchCount
        ? result.matchHighlightSearchCount
        : 0;
      this.highlightsCount = this.currentText.highlightsCount;
      // if (this.currentText.highlightSearch) {
      //   this.highlightSearchTyped = this.currentText.highlightSearch;
      // }
      this.setHighlightsWords();
    },
    closeDrawer() {
      this.versionsOpen = false;
      this.moreOpen = false;
      this.highlightSearchInput = false;
    },
    loadVersion(version) {
      this.currentText = JSON.parse(JSON.stringify(version));
      this.highlightsCount = this.currentText.highlightsCount;
      this.recalculateHighlights();
      this.closeDrawer();
    },
  },
};
</script>

<template>
  <div
    :class="[
      'info-list-details-trigger',
      {
        'info-list-details-triggerOpen': expanded,
      },
    ]"
  >
    <div
      @mouseleave="expanded = false"
      @mouseover="expanded = true"
      @click="showTextToggle()"
    >
      <div class="info-list-details-triggerText">
        <transition name="fade">
          <div v-if="expanded">Pokaż tekst</div>
        </transition>
      </div>

      <div class="info-list-details-triggerIcon">
        <img :src="require(`@/assets/icons/ArrowRotated.svg`)" />
      </div>
    </div>
    <base-modal
      :open="modal"
      @close="closeModal"
      full-height
      theme="mobileCloseX"
      ref="baseModalRef"
      :overflow-hidden="false"
      :large="comparisonMode"
      show-scroll-top
      show-close-sticky
    >
      <div v-if="currentText" class="info-modal">
        <div style="display: flex; gap: 30px; justify-content: space-between">
          <div>
            <div v-if="title" class="project-title">{{ title }}</div>
            <h3 class="version-title title mb-4" v-html="text.name" />
          </div>
          <div>
            <portal-target name="comparison-navigation" />
          </div>
        </div>
        <div
          v-if="
            !refreshTopContainer &&
              highlightWords &&
              highlightWords.length > 0 &&
              !comparisonMode
          "
          class="versions-top-container"
        >
          <div class="versions-tags mb-4">
            <div
              v-for="(highlightCount, i) in highlightWords"
              :key="`counts-${highlightCount.word}`"
              :class="[
                'versions-tag versions-tag--count',
                {
                  'versions-tag--active': highlightCount.active,
                },
              ]"
              @click="toggleHighlightWord(highlightCount.word)"
            >
              {{ highlightCount.word.replaceAll('\\b', '') }}
              <span>{{ highlightCount.count }}</span>
            </div>
            <div
              v-if="
                !highlightsCountsShowAll &&
                  currentText.highlightWords &&
                  currentText.highlightWords.length > 4
              "
              class="versions-tag"
              @click="
                () => {
                  highlightsCountsShowAll = true;
                  setHighlightsWords();
                }
              "
            >
              ...
            </div>
          </div>
          <div class="version-handlers">
            <div
              class="version-handlers--unselect"
              @click="
                currentText.highlightWords.filter(item => item.active).length >
                0
                  ? unselectAllHighlights()
                  : selectAllHighlights()
              "
            >
              <template
                v-if="
                  currentText.highlightWords.filter(item => item.active)
                    .length > 0
                "
              >
                odznacz wszystkie frazy
              </template>
              <template v-else>
                zaznacz wszystkie frazy
              </template>
            </div>
            <div class="version-handlers--sorts">
              <div>sortuj</div>
              <div
                :class="[
                  'version-handlers--sort',
                  {
                    active: sortHighlightsBy === 'amount',
                  },
                ]"
                @click="
                  () => {
                    if (sortHighlightsBy === 'amount') {
                      sortHighlightsDirection =
                        sortHighlightsDirection === 'asc' ? 'desc' : 'asc';
                    } else {
                      sortHighlightsDirection = 'desc';
                    }
                    sortHighlightsBy = 'amount';
                    setHighlightsWords();
                  }
                "
              >
                <svg
                  v-if="
                    sortHighlightsDirection === 'desc' ||
                      sortHighlightsBy === 'alphabet'
                  "
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 6H23"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 10H21"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 14H19"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 18H17"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.75 5C6.75 4.58579 6.41421 4.25 6 4.25C5.58579 4.25 5.25 4.58579 5.25 5L6.75 5ZM5.46967 19.5303C5.76256 19.8232 6.23744 19.8232 6.53033 19.5303L11.3033 14.7574C11.5962 14.4645 11.5962 13.9896 11.3033 13.6967C11.0104 13.4038 10.5355 13.4038 10.2426 13.6967L6 17.9393L1.75736 13.6967C1.46447 13.4038 0.989593 13.4038 0.6967 13.6967C0.403806 13.9896 0.403806 14.4645 0.6967 14.7574L5.46967 19.5303ZM5.25 5L5.25 19L6.75 19L6.75 5L5.25 5Z"
                    fill="currentColor"
                  />
                </svg>
                <svg
                  v-else
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 18H23"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 14H21"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 10H19"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 6H17"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.75 5C6.75 4.58579 6.41421 4.25 6 4.25C5.58579 4.25 5.25 4.58579 5.25 5L6.75 5ZM5.46967 19.5303C5.76256 19.8232 6.23744 19.8232 6.53033 19.5303L11.3033 14.7574C11.5962 14.4645 11.5962 13.9896 11.3033 13.6967C11.0104 13.4038 10.5355 13.4038 10.2426 13.6967L6 17.9393L1.75736 13.6967C1.46447 13.4038 0.989593 13.4038 0.6967 13.6967C0.403806 13.9896 0.403806 14.4645 0.6967 14.7574L5.46967 19.5303ZM5.25 5L5.25 19L6.75 19L6.75 5L5.25 5Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div
                :class="[
                  'version-handlers--sort',
                  {
                    active: sortHighlightsBy === 'alphabet',
                  },
                ]"
                @click="
                  () => {
                    if (sortHighlightsBy === 'alphabet') {
                      sortHighlightsDirection =
                        sortHighlightsDirection === 'asc' ? 'desc' : 'asc';
                    } else {
                      sortHighlightsDirection = 'desc';
                    }
                    sortHighlightsBy = 'alphabet';
                    setHighlightsWords();
                  }
                "
              >
                <svg
                  v-if="
                    sortHighlightsDirection === 'desc' ||
                      sortHighlightsBy === 'amount'
                  "
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.75 5C6.75 4.58579 6.41421 4.25 6 4.25C5.58579 4.25 5.25 4.58579 5.25 5L6.75 5ZM5.46967 19.5303C5.76256 19.8232 6.23744 19.8232 6.53033 19.5303L11.3033 14.7574C11.5962 14.4645 11.5962 13.9896 11.3033 13.6967C11.0104 13.4038 10.5355 13.4038 10.2426 13.6967L6 17.9393L1.75736 13.6967C1.46447 13.4038 0.989593 13.4038 0.6967 13.6967C0.403806 13.9896 0.403806 14.4645 0.6967 14.7574L5.46967 19.5303ZM5.25 5L5.25 19L6.75 19L6.75 5L5.25 5Z"
                    fill="currentColor"
                  />
                  <path
                    d="M21.1826 11H19.6104L18.9854 9.37402H16.124L15.5332 11H14L16.7881 3.8418H18.3164L21.1826 11ZM18.5215 8.16797L17.5352 5.51172L16.5684 8.16797H18.5215Z"
                    fill="currentColor"
                  />
                  <path
                    d="M14.1074 20V18.6963L17.8672 14.0527H14.5322V12.8418H19.7715V13.9648L15.8506 18.7939H19.9229V20H14.1074Z"
                    fill="currentColor"
                  />
                </svg>
                <svg
                  v-else
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.75 5C6.75 4.58579 6.41421 4.25 6 4.25C5.58579 4.25 5.25 4.58579 5.25 5L6.75 5ZM5.46967 19.5303C5.76256 19.8232 6.23744 19.8232 6.53033 19.5303L11.3033 14.7574C11.5962 14.4645 11.5962 13.9896 11.3033 13.6967C11.0104 13.4038 10.5355 13.4038 10.2426 13.6967L6 17.9393L1.75736 13.6967C1.46447 13.4038 0.989593 13.4038 0.6967 13.6967C0.403806 13.9896 0.403806 14.4645 0.6967 14.7574L5.46967 19.5303ZM5.25 5L5.25 19L6.75 19L6.75 5L5.25 5Z"
                    fill="currentColor"
                  />
                  <path
                    d="M14.1074 11V9.69629L17.8672 5.05273H14.5322V3.8418H19.7715V4.96484L15.8506 9.79395H19.9229V11H14.1074Z"
                    fill="currentColor"
                  />
                  <path
                    d="M21.1826 20H19.6104L18.9854 18.374H16.124L15.5332 20H14L16.7881 12.8418H18.3164L21.1826 20ZM18.5215 17.168L17.5352 14.5117L16.5684 17.168H18.5215Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <p
          v-if="currentText && !comparisonMode"
          v-html="currentText.content"
          class="text-justify"
          ref="textContent"
        />
        <text-versions-comparison
          v-else-if="comparisonMode"
          :versions="textPrepared.versions"
          :current="currentText"
          :item-id="text.itemId"
          :item-key="text.itemKey"
          @compare="v => (comparisonMode = v)"
        />
        <div
          ref="versions"
          class="versions"
          :class="{ 'versions--open': versionsOpen || moreOpen }"
        >
          <div class="versions__inner">
            <div class="versions__sticky">
              <div class="d-flex justify-space-between align-items-center mb-4">
                <div
                  v-if="versionsOpen"
                  class="title d-flex align-items-center gap-2"
                >
                  <img
                    :src="require('@/assets/icons/FloatingBarSecondary.svg')"
                  />
                  Wybierz wersję
                </div>
                <div v-else></div>
                <base-button-exit
                  @click="closeDrawer"
                  class="versions__close-btn"
                />
              </div>
              <ul class="mb-8">
                <template v-if="versionsOpen && !moreOpen">
                  <li
                    v-for="version in textPrepared.versions"
                    :key="version.id"
                    class="mb-1"
                  >
                    <button
                      @click="loadVersion(version)"
                      class="version-button mb-2"
                      :class="{ active: version.id === currentText.id }"
                    >
                      <div class="d-flex align-items-center">
                        <span class="version-button__date">{{
                          version.date
                        }}</span>
                        <span class="version-button__desc">
                          {{ version.stage_description }}
                        </span>
                      </div>
                      <img
                        :src="
                          require(`@/assets/icons/File${version.format.toUpperCase()}.svg`)
                        "
                      />
                      <div
                        v-if="
                          version.highlightSearchCounts ||
                            version.highlightsCount
                        "
                        class="lens-icon"
                      >
                        <img
                          v-if="
                            version.highlightSearchCounts &&
                              version.highlightsCount
                          "
                          :src="require('@/assets/icons/LensUniversal.svg')"
                        />
                        <img
                          v-else-if="
                            version.highlightSearchCounts &&
                              !version.highlightsCount
                          "
                          :src="require('@/assets/icons/LensSearch.svg')"
                        />
                        <img
                          v-else-if="
                            !version.highlightSearchCounts &&
                              version.highlightsCount
                          "
                          :src="require('@/assets/icons/LensMonitored.svg')"
                        />
                      </div>
                    </button>
                  </li>
                </template>
                <template v-else-if="!versionsOpen && moreOpen">
                  <li class="mb-1">
                    <button
                      v-if="allowCompare"
                      :class="['version-button mb-2']"
                      @click="
                        () => {
                          if ($store.getters['user/isLoggedIn']) {
                            comparisonMode = true;
                            closeDrawer();
                          } else {
                            this.$store.commit('openGuestModal');
                          }
                        }
                      "
                    >
                      <div class="d-flex align-items-center gap-2 font-bold">
                        <img
                          :src="require(`@/assets/icons/BookComparison.svg`)"
                        />
                        porównaj dokumenty
                      </div>
                    </button>
                    <button
                      :class="[
                        'version-button mb-2',
                        {
                          active: currentPhrases === 'monitored',
                        },
                      ]"
                      @click="
                        () => {
                          closeDrawer();
                          currentPhrases = 'monitored';
                        }
                      "
                    >
                      <div class="d-flex align-items-center gap-2 font-bold">
                        <img
                          :src="require(`@/assets/icons/LensMonitored.svg`)"
                        />
                        monitorowane frazy
                      </div>
                    </button>
                    <button
                      v-if="!highlightSearchInput"
                      :class="[
                        'version-button mb-2',
                        {
                          active: currentPhrases === 'search',
                        },
                      ]"
                      @click="openHighlightSearchInput()"
                    >
                      <div class="d-flex align-items-center gap-2 font-bold">
                        <img :src="require(`@/assets/icons/LensSearch.svg`)" />
                        <template v-if="highlightSearchComputed">
                          wyniki dla “{{ highlightSearchComputed }}”
                        </template>
                      </div>
                      <img :src="require(`@/assets/icons/EditGrey.svg`)" />
                    </button>
                    <base-input
                      v-else
                      ref="highlightSearchInputRef"
                      v-model="highlightSearchTyped"
                      class="mb-2"
                      @enter="
                        () => {
                          closeDrawer();
                          applyNewSearch();
                        }
                      "
                    >
                      <template #icon>
                        <img
                          class="cursor-pointer"
                          :src="require(`@/assets/icons/LensBlue.svg`)"
                          @click="
                            () => {
                              closeDrawer();
                              applyNewSearch();
                            }
                          "
                        />
                      </template>
                    </base-input>

                    <a
                      class="version-button mb-2"
                      :href="currentText.link"
                      target="_blank"
                    >
                      <div class="d-flex align-items-center gap-2 font-bold">
                        <img
                          :src="require(`@/assets/icons/DownloadGrey.svg`)"
                        />
                        pobierz
                      </div>
                      <img
                        v-if="currentText.linkFormat"
                        :src="
                          require(`@/assets/icons/File${currentText.linkFormat.toUpperCase()}.svg`)
                        "
                      />
                    </a>
                    <!--                  <button class="version-button mb-2">-->
                    <!--                    <div class="d-flex align-items-center gap-2 font-bold">-->
                    <!--                      <img :src="require(`@/assets/icons/Compare.svg`)" />-->
                    <!--                      porównaj-->
                    <!--                    </div>-->
                    <!--                  </button>-->
                  </li>
                </template>
              </ul>
              <base-button-exit
                @click="closeDrawer"
                class="versions__close-btn"
              />
            </div>
          </div>
        </div>
        <text-versions-navigation
          v-if="currentText && !comparisonMode"
          v-model="navigator"
          :total-monitored-highlights="highlightsCount"
          :total-search-highlights="highlightSearchCount"
          :search="highlightSearchTyped"
          :versions="textPrepared.versions"
          :active-phrases="currentPhrases"
          :compare-enabled="allowCompare"
          :download-link="
            currentText && currentText.link ? currentText.link : null
          "
          @toggle-versions="
            v => {
              versionsOpen = v;
              moreOpen = false;
            }
          "
          @toggle-comparison="v => (comparisonMode = v)"
          @toggle-more="
            v => {
              versionsOpen = false;
              moreOpen = v;
            }
          "
          @search="
            v => {
              this.highlightSearchTyped = v;
              this.applyNewSearch();
            }
          "
          @download="downloadVersion"
        >
          <template #versionDate>
            {{ currentText.date }}
          </template>
        </text-versions-navigation>
      </div>
    </base-modal>
  </div>
</template>

<style scoped lang="scss">
.info-list-details-trigger {
  position: sticky;
  top: 133px;
  right: 0;
  min-width: 33px;
  margin-bottom: -33px;
  margin-right: -15px;
  float: right;

  width: 33px;
  > div {
    display: inline-flex;

    cursor: pointer;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &Open {
    .info-list-details-triggerText {
      width: 120px;
      opacity: 1;
    }
  }
  &Text {
    white-space: nowrap;
    color: white;
    font-size: 10px;
    font-weight: 400;
    //padding-left: 15px;
    //padding-right: 43px;
    margin-right: -33px;
    height: 32px;
    background-color: $primary;
    border-radius: 20px;
    position: absolute;
    width: 33px;
    right: 33.5px;
    top: 0.5px;
    opacity: 0;
    transition: width 100ms ease-in-out, opacity 100ms ease-in-out;
    > div {
      width: 100%;
      padding-left: 20px;
    }
  }
  &Icon {
    border-radius: 100%;
    background-color: white;
    height: 33px;
    width: 33px;
    box-shadow: 0px 1.43px 15.74px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 1;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.versions-header {
  //max-width: 36rem;
}
.versions-header__download {
  margin-right: 1rem;
}

.project-title {
  color: $gray-3;
  font-weight: bold;
  font-size: 0.8rem;
}

.title {
  font-size: 1.28rem;
  font-weight: bold;
  color: $primary;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 6px;
  img {
    width: 18px;
    height: 18px;
  }
}
.version-title {
  margin-top: 0.85rem;
  margin-right: 3rem;
}

.versions-tags {
  display: flex;
  gap: 10px;
  @include mq('tablet') {
    flex-wrap: wrap;
    flex-direction: row;
  }
  flex-direction: column;
  align-items: start;
  .versions-tag {
    min-height: 33px;
    white-space: nowrap;
    border-radius: 27px;
    padding: 4px 12px;
    background-color: rgba(216, 216, 216, 0.5);
    color: #363734;
    font-size: 12px;
    cursor: pointer;
    -webkit-transition: color 50ms ease-in-out;
    transition: color 50ms ease-in-out;
    display: flex;
    gap: 6px;
    align-items: center;
    user-select: none;
    span {
      background-color: rgba(216, 216, 216, 0.5);
      height: 25px;
      min-width: 25px;
      padding-inline: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
    }
    &--active {
      background-color: #fff;
      color: #0042ff;
      -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    }
    &--count {
      padding-right: 4px;
    }
  }
}

.versions {
  overflow: hidden;
  z-index: 11;
  position: absolute;
  top: -3px;
  bottom: -3px;
  right: -3px;
  width: 30rem;
  max-width: 102%;
  //overflow: hidden;
  text-align: center;
  pointer-events: none;
  transition: transform 0.15s $easeOutQuad;

  &--open {
    pointer-events: auto;

    .versions__inner {
      opacity: 1;
      transform: translateY(0);
      transition: transform 0.3s $easeOutQuad;
      z-index: auto;
    }
  }
}
.versions__sticky {
  position: sticky;
  top: 0;
}
.versions--open {
  overflow: visible !important;
  .versions__inner {
    height: 100vh;
    position: sticky;
    top: 0;
    background-color: white;
    overflow-y: auto;
  }
}
.versions__inner {
  opacity: 0;
  z-index: -1000;
  //overflow: auto;
  padding: 3rem 1.5rem 2rem;
  border-radius: 1.5rem;
  border: 3px solid $gray-4;
  transform: translateY(100vh);
  transition: opacity 0.5s ease-in-out, transform 0.5s $easeOutQuad;
}
.versions__close-btn {
  padding: 1rem;
}
.versions__close {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $gray-5;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 1;

  &--active {
    opacity: 0.6;
    pointer-events: auto;

    &:hover {
      opacity: 0.4;
    }
  }
}

.version-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 1.5rem 0.9rem;
  border: 2px solid $gray-5;
  border-radius: 0.4rem;
  font-size: 1.14rem;
  text-align: left;
  color: $gray-1;
  transition: background-color 0.15s;
  position: relative;

  @include mq('phone') {
    display: flex;
  }

  &.active,
  &:hover {
    border-color: $primary;
  }

  .lens-icon {
    position: absolute;
    top: 13px;
    right: -14px;
    background-color: white;
    padding-top: 1px;
    display: flex;
  }
}
.version-button__date {
  display: block;
  margin-right: 3rem;
}
.version-button__desc {
  font-weight: bold;
}
.version-handlers {
  display: flex;
  @include mq('tablet') {
    flex-direction: column;
    align-items: end;
  }
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
  color: $gray-2;
  &--unselect {
    white-space: nowrap;
    text-decoration: underline;
    cursor: pointer;
  }
  &--sorts {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    justify-content: end;
  }
  &--sort {
    cursor: pointer;
    &.active {
      color: $primary;
    }
    &:not(.active) {
      color: $gray-2;
    }
  }
}

.versions-top-container {
  display: flex;
  @include mq('tablet') {
    justify-content: space-between;
    flex-direction: row;
  }
  flex-direction: column-reverse;
}

.info-modal {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  p {
    flex-grow: 1;
  }
}
</style>
