var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.open = false)),expression:"() => (open = false)"}],class:[
    'text-versions-comparison-select',
    {
      'text-versions-comparison-select--open': _vm.open,
    },
  ],on:{"click":function($event){_vm.open = !_vm.open}}},[_c('div',{staticClass:"text-versions-comparison-select__value"},[_c('div',{staticClass:"text-versions-comparison-select__valueContent"},[(_vm.valueItem)?[_c('div',{staticClass:"text-versions-comparison-select__valueDate"},[_vm._v("\n          "+_vm._s(_vm.valueItem.date)+" r.\n        ")]),_vm._v(" "),_c('div',{staticClass:"text-versions-comparison-select__valueTitle"},[_vm._v("\n          "+_vm._s(_vm.valueItem.description || _vm.valueItem.stage_description)+"\n        ")]),_vm._v(" "),_c('img',{attrs:{"src":require(`@/assets/icons/File${
              _vm.valueItem.format
                ? _vm.valueItem.format.toUpperCase()
                : _vm.valueItem.linkFormat
                ? _vm.valueItem.linkFormat.toUpperCase()
                : null
            }.svg`),"width":"14"}})]:_vm._e()],2),_vm._v(" "),_c('svg',{style:(`transition: transform 100ms ease-in-out; transform: rotate(${
          _vm.open ? `180deg` : `0deg`
        })`),attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('mask',{staticStyle:{"mask-type":"alpha"},attrs:{"id":"mask0_3856_16437","maskUnits":"userSpaceOnUse","x":"0","y":"0","width":"24","height":"24"}},[_c('rect',{attrs:{"x":"24","y":"24","width":"24","height":"24","transform":"rotate(-180 24 24)","fill":"#D9D9D9"}})]),_vm._v(" "),_c('g',{attrs:{"mask":"url(#mask0_3856_16437)"}},[_c('path',{attrs:{"d":"M12 13.2L16.6 8.6L18 10L12 16L6 10L7.4 8.6L12 13.2Z","fill":"#9B9B9B"}})])])]),_vm._v(" "),_c('Transition',{attrs:{"name":"slide-select"}},[(_vm.open)?_c('div',{staticClass:"text-versions-comparison-select__list"},_vm._l((_vm.options),function(option,i){return _c('div',{key:`option-${i}`,class:[
          'text-versions-comparison-select__listOption',
          {
            'text-versions-comparison-select__listOption--disabled':
              option.disabled || option.value === _vm.selectValue,
          },
        ],on:{"click":function($event){return _vm.setValue(option)}}},[_c('div',[_vm._v(_vm._s(option.data.date)+" r.")]),_vm._v(" "),_c('div',[_vm._v("\n          "+_vm._s(option.label || option.data.stage_description)+"\n        ")]),_vm._v(" "),_c('img',{attrs:{"src":require(`@/assets/icons/File${
              option.data.format
                ? option.data.format.toUpperCase()
                : option.data.linkFormat
                ? option.data.linkFormat.toUpperCase()
                : null
            }.svg`),"width":"14"}})])}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }