export const prepareTextLinks = data => {
  if (!data.versions_texts_motives_osr) {
    return [];
  }
  return Object.entries(data.versions_texts_motives_osr).map(([date, rest]) => {
    const stages = Object.entries(rest).map(([stage, links]) => ({
      name: stage,
      links: links.map(({ scope, form, link }) => ({
        link,
        extension: form,
        text: scope === 'text' ? 'tekst' : scope,
        isText: scope === 'text',
      })),
    }));
    return {
      date,
      stages,
    };
  });
};
