// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!./mapTextWorker';
import * as Comlink from 'comlink';

const worker = Comlink.wrap(new Worker());
const worker2 = Comlink.wrap(new Worker());
const worker3 = Comlink.wrap(new Worker());
const worker4 = Comlink.wrap(new Worker());
const worker5 = Comlink.wrap(new Worker());
const worker6 = Comlink.wrap(new Worker());
const worker7 = Comlink.wrap(new Worker());
const worker8 = Comlink.wrap(new Worker());

export const mapTextWorker = worker.mapText;
export const mapTextWorker2 = worker2.mapText;
export const mapTextWorker3 = worker3.mapText;
export const mapTextWorker4 = worker4.mapText;
export const mapTextWorker5 = worker5.mapText;
export const mapTextWorker6 = worker6.mapText;
export const mapTextWorker7 = worker7.mapText;
export const mapTextWorker8 = worker8.mapText;

// export function sendMapText(msg) {
//   worker.postMessage(msg);
// }
