<template>
  <div>
    <base-button
      v-if="list.length || loading"
      @click="open = true"
      theme="primary"
      :loading="loading"
      full
      class="text-version-button-trigger"
    >
      Najnowszy tekst projektu
      <div v-if="(highlightSearchCount || highlightsCount) && initialized">
        <img
          v-if="highlightSearchCount && highlightsCount"
          :src="require('@/assets/icons/LensUniversal.svg')"
        />
        <img
          v-else-if="highlightSearchCount && !highlightsCount"
          :src="require('@/assets/icons/LensSearch.svg')"
        />
        <img
          v-else-if="!highlightSearchCount && highlightsCount"
          :src="require('@/assets/icons/LensMonitored.svg')"
        />
      </div>
      <div v-else-if="!initialized">
        <BaseSpinner small />
      </div>
    </base-button>
    <base-modal
      v-if="!empty"
      :open="open"
      @close="closeModal"
      full-height
      theme="mobileCloseX"
      ref="baseModalRef"
      :large="comparisonMode"
      show-scroll-top
      show-close-sticky
    >
      <base-spinner v-if="loading" />
      <template v-else>
        <div class="versions-header row--between row--no-gutters mb-12">
          <div v-if="loadingVersion" class="mb-12">
            <base-spinner />
          </div>
          <template v-else-if="current">
            <div
              style="display: flex; gap: 30px; justify-content: space-between"
            >
              <div>
                <div class="project-title">{{ item.title }}</div>
                <h3 v-if="comparisonMode" class="version-title title mb-4">
                  <span>Porównanie</span>
                </h3>
                <template v-else>
                  <h3 class="version-title title mb-4">
                    <span v-html="current.title" />
                    <img
                      width="16"
                      :src="
                        require(`@/assets/icons/File${current.linkFormat.toUpperCase()}.svg`)
                      "
                    />
                  </h3>
                </template>
              </div>
              <div>
                <portal-target name="comparison-navigation" />
              </div>
            </div>
            <div v-if="!comparisonMode" class="versions-top-container">
              <div>
                <div
                  v-if="
                    sortedHighlightsCounts && sortedHighlightsCounts.length > 0
                  "
                  class="versions-tags"
                >
                  <div
                    v-for="highlightCount in sortedHighlightsCounts"
                    :key="`counts-${highlightCount.tag}`"
                    :class="[
                      'versions-tag versions-tag--count',
                      {
                        'versions-tag--active': highlightCount.active,
                      },
                    ]"
                    @click="
                      () => {
                        highlightCount.active = !highlightCount.active;
                        recalculateHighlights();
                      }
                    "
                  >
                    {{ highlightCount.text.replaceAll('\\b', '') }}
                    <span>{{ highlightCount.count }}</span>
                  </div>
                  <div
                    v-if="
                      !highlightsCountsShowAll && highlightsCounts.length > 3
                    "
                    class="versions-tag"
                    @click="highlightsCountsShowAll = true"
                  >
                    ...
                  </div>
                </div>
              </div>
              <div v-if="highlightsCounts" class="version-handlers">
                <div
                  class="version-handlers--unselect"
                  @click="
                    highlightsCounts.filter(item => item.active).length > 0
                      ? unselectAllHighlights()
                      : selectAllHighlights()
                  "
                >
                  <template
                    v-if="
                      highlightsCounts.filter(item => item.active).length > 0
                    "
                  >
                    odznacz wszystkie frazy
                  </template>
                  <template v-else>
                    zaznacz wszystkie frazy
                  </template>
                </div>
                <div class="version-handlers--sorts">
                  <div>sortuj</div>
                  <div
                    :class="[
                      'version-handlers--sort',
                      {
                        active: sortHighlightsBy === 'amount',
                      },
                    ]"
                    @click="
                      () => {
                        if (sortHighlightsBy === 'amount') {
                          sortHighlightsDirection =
                            sortHighlightsDirection === 'asc' ? 'desc' : 'asc';
                        } else {
                          sortHighlightsDirection = 'desc';
                        }
                        sortHighlightsBy = 'amount';
                      }
                    "
                  >
                    <svg
                      v-if="
                        sortHighlightsDirection === 'desc' ||
                          sortHighlightsBy === 'alphabet'
                      "
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 6H23"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 10H21"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 14H19"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 18H17"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.75 5C6.75 4.58579 6.41421 4.25 6 4.25C5.58579 4.25 5.25 4.58579 5.25 5L6.75 5ZM5.46967 19.5303C5.76256 19.8232 6.23744 19.8232 6.53033 19.5303L11.3033 14.7574C11.5962 14.4645 11.5962 13.9896 11.3033 13.6967C11.0104 13.4038 10.5355 13.4038 10.2426 13.6967L6 17.9393L1.75736 13.6967C1.46447 13.4038 0.989593 13.4038 0.6967 13.6967C0.403806 13.9896 0.403806 14.4645 0.6967 14.7574L5.46967 19.5303ZM5.25 5L5.25 19L6.75 19L6.75 5L5.25 5Z"
                        fill="currentColor"
                      />
                    </svg>
                    <svg
                      v-else
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 18H23"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 14H21"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 10H19"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 6H17"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.75 5C6.75 4.58579 6.41421 4.25 6 4.25C5.58579 4.25 5.25 4.58579 5.25 5L6.75 5ZM5.46967 19.5303C5.76256 19.8232 6.23744 19.8232 6.53033 19.5303L11.3033 14.7574C11.5962 14.4645 11.5962 13.9896 11.3033 13.6967C11.0104 13.4038 10.5355 13.4038 10.2426 13.6967L6 17.9393L1.75736 13.6967C1.46447 13.4038 0.989593 13.4038 0.6967 13.6967C0.403806 13.9896 0.403806 14.4645 0.6967 14.7574L5.46967 19.5303ZM5.25 5L5.25 19L6.75 19L6.75 5L5.25 5Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div
                    :class="[
                      'version-handlers--sort',
                      {
                        active: sortHighlightsBy === 'alphabet',
                      },
                    ]"
                    @click="
                      () => {
                        if (sortHighlightsBy === 'alphabet') {
                          sortHighlightsDirection =
                            sortHighlightsDirection === 'asc' ? 'desc' : 'asc';
                        } else {
                          sortHighlightsDirection = 'desc';
                        }
                        sortHighlightsBy = 'alphabet';
                      }
                    "
                  >
                    <svg
                      v-if="
                        sortHighlightsDirection === 'desc' ||
                          sortHighlightsBy === 'amount'
                      "
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.75 5C6.75 4.58579 6.41421 4.25 6 4.25C5.58579 4.25 5.25 4.58579 5.25 5L6.75 5ZM5.46967 19.5303C5.76256 19.8232 6.23744 19.8232 6.53033 19.5303L11.3033 14.7574C11.5962 14.4645 11.5962 13.9896 11.3033 13.6967C11.0104 13.4038 10.5355 13.4038 10.2426 13.6967L6 17.9393L1.75736 13.6967C1.46447 13.4038 0.989593 13.4038 0.6967 13.6967C0.403806 13.9896 0.403806 14.4645 0.6967 14.7574L5.46967 19.5303ZM5.25 5L5.25 19L6.75 19L6.75 5L5.25 5Z"
                        fill="currentColor"
                      />
                      <path
                        d="M21.1826 11H19.6104L18.9854 9.37402H16.124L15.5332 11H14L16.7881 3.8418H18.3164L21.1826 11ZM18.5215 8.16797L17.5352 5.51172L16.5684 8.16797H18.5215Z"
                        fill="currentColor"
                      />
                      <path
                        d="M14.1074 20V18.6963L17.8672 14.0527H14.5322V12.8418H19.7715V13.9648L15.8506 18.7939H19.9229V20H14.1074Z"
                        fill="currentColor"
                      />
                    </svg>
                    <svg
                      v-else
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.75 5C6.75 4.58579 6.41421 4.25 6 4.25C5.58579 4.25 5.25 4.58579 5.25 5L6.75 5ZM5.46967 19.5303C5.76256 19.8232 6.23744 19.8232 6.53033 19.5303L11.3033 14.7574C11.5962 14.4645 11.5962 13.9896 11.3033 13.6967C11.0104 13.4038 10.5355 13.4038 10.2426 13.6967L6 17.9393L1.75736 13.6967C1.46447 13.4038 0.989593 13.4038 0.6967 13.6967C0.403806 13.9896 0.403806 14.4645 0.6967 14.7574L5.46967 19.5303ZM5.25 5L5.25 19L6.75 19L6.75 5L5.25 5Z"
                        fill="currentColor"
                      />
                      <path
                        d="M14.1074 11V9.69629L17.8672 5.05273H14.5322V3.8418H19.7715V4.96484L15.8506 9.79395H19.9229V11H14.1074Z"
                        fill="currentColor"
                      />
                      <path
                        d="M21.1826 20H19.6104L18.9854 18.374H16.124L15.5332 20H14L16.7881 12.8418H18.3164L21.1826 20ZM18.5215 17.168L17.5352 14.5117L16.5684 17.168H18.5215Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>

        <base-spinner
          v-if="loadingVersion || recalculatingHighlightsTimeout"
          large
        />
        <div
          v-else-if="current && !comparisonMode"
          ref="versionTextContainer"
          class="mb-12"
          v-html="current.text"
        />
        <text-versions-comparison
          v-else-if="comparisonMode"
          :versions="list"
          :current="current"
          item-key="version"
          :item-id="item.id"
          @compare="v => (comparisonMode = v)"
        />

        <div
          v-if="!comparisonMode"
          ref="versions"
          @touchstart="onTouchStart"
          @touchmove="onTouchMove"
          @touchend="onTouchEnd"
          class="versions"
          :class="{ 'versions--open': versionsOpen || moreOpen }"
        >
          <div class="versions__inner">
            <div class="versions__sticky">
              <div class="d-flex justify-space-between align-items-center mb-4">
                <div
                  v-if="versionsOpen"
                  class="title d-flex align-items-center gap-2"
                >
                  <img
                    :src="require('@/assets/icons/FloatingBarSecondary.svg')"
                  />
                  Wybierz wersję
                </div>
                <div v-else></div>
                <base-button-exit
                  @click="closeDrawer"
                  class="versions__close-btn"
                />
              </div>
              <ul class="mb-8">
                <template v-if="versionsOpen && !moreOpen">
                  <li v-for="version in list" :key="version.id" class="mb-1">
                    <button
                      @click="loadVersion(version.id)"
                      class="version-button mb-2"
                      :class="{ active: version.id === current.id }"
                      :disabled="!version.current"
                    >
                      <div class="d-flex align-items-center">
                        <span class="version-button__date">{{
                          version.date
                        }}</span>
                        <span class="version-button__desc">
                          {{ version.description }}
                        </span>
                      </div>
                      <img
                        :src="
                          require(`@/assets/icons/File${version.linkFormat.toUpperCase()}.svg`)
                        "
                      />
                      <div
                        v-if="
                          (version.current &&
                            (version.current.highlightSearchCounts ||
                              version.current.highlightsCount)) ||
                            !version.current
                        "
                        class="lens-icon"
                      >
                        <img
                          v-if="
                            version.current &&
                              version.current.highlightSearchCounts &&
                              version.current.highlightsCount
                          "
                          :src="require('@/assets/icons/LensUniversal.svg')"
                        />
                        <img
                          v-else-if="
                            version.current &&
                              version.current.highlightSearchCounts &&
                              !version.current.highlightsCount
                          "
                          :src="require('@/assets/icons/LensSearch.svg')"
                        />
                        <img
                          v-else-if="
                            version.current &&
                              !version.current.highlightSearchCounts &&
                              version.current.highlightsCount
                          "
                          :src="require('@/assets/icons/LensMonitored.svg')"
                        />
                        <div v-else>
                          <BaseSpinner small />
                        </div>
                      </div>
                    </button>
                  </li>
                </template>
                <template v-else-if="!versionsOpen && moreOpen">
                  <li class="mb-1">
                    <button
                      v-if="allowCompare"
                      :class="['version-button mb-2']"
                      @click="
                        () => {
                          if ($store.getters['user/isLoggedIn']) {
                            comparisonMode = true;
                            closeDrawer();
                          } else {
                            this.$store.commit('openGuestModal');
                          }
                        }
                      "
                    >
                      <div class="d-flex align-items-center gap-2 font-bold">
                        <img
                          :src="require(`@/assets/icons/BookComparison.svg`)"
                        />
                        porównaj dokumenty
                      </div>
                    </button>
                    <button
                      :class="[
                        'version-button mb-2',
                        {
                          active: currentPhrases === 'monitored',
                        },
                      ]"
                      @click="
                        () => {
                          closeDrawer();
                          currentPhrases = 'monitored';
                        }
                      "
                    >
                      <div class="d-flex align-items-center gap-2 font-bold">
                        <img
                          :src="require(`@/assets/icons/LensMonitored.svg`)"
                        />
                        monitorowane frazy
                      </div>
                    </button>
                    <button
                      v-if="!highlightSearchInput"
                      :class="[
                        'version-button mb-2',
                        {
                          active: currentPhrases === 'search',
                        },
                      ]"
                      @click="toggleSearchInput()"
                    >
                      <div class="d-flex align-items-center gap-2 font-bold">
                        <img :src="require(`@/assets/icons/LensSearch.svg`)" />
                        <template v-if="highlightSearchComputed">
                          wyniki dla “{{ highlightSearchComputed }}”
                        </template>
                      </div>
                      <img :src="require(`@/assets/icons/EditGrey.svg`)" />
                    </button>
                    <base-input
                      v-else
                      ref="highlightSearchInput"
                      v-model="highlightSearchTyped"
                      class="mb-2"
                      type="search"
                      @enter="
                        () => {
                          closeDrawer();
                          applyNewSearch();
                        }
                      "
                    >
                      <template #icon>
                        <img
                          class="cursor-pointer"
                          :src="require(`@/assets/icons/LensBlue.svg`)"
                          @click="
                            () => {
                              closeDrawer();
                              applyNewSearch();
                            }
                          "
                        />
                      </template>
                    </base-input>

                    <a
                      class="version-button mb-2"
                      :href="current.link"
                      target="_blank"
                    >
                      <div class="d-flex align-items-center gap-2 font-bold">
                        <img
                          :src="require(`@/assets/icons/DownloadGrey.svg`)"
                        />
                        pobierz
                      </div>
                      <img
                        v-if="current.linkFormat"
                        :src="
                          require(`@/assets/icons/File${current.linkFormat.toUpperCase()}.svg`)
                        "
                      />
                    </a>
                    <!--                  <button class="version-button mb-2">-->
                    <!--                    <div class="d-flex align-items-center gap-2 font-bold">-->
                    <!--                      <img :src="require(`@/assets/icons/Compare.svg`)" />-->
                    <!--                      porównaj-->
                    <!--                    </div>-->
                    <!--                  </button>-->
                  </li>
                </template>
              </ul>
              <base-button-exit
                @click="closeDrawer"
                class="versions__close-btn"
              />
            </div>
          </div>
        </div>
        <text-versions-navigation
          v-if="!recalculatingHighlightsTimeout && !comparisonMode"
          v-model="navigator"
          :total-monitored-highlights="highlightsCount"
          :total-search-highlights="highlightSearchCount"
          :search="highlightSearchComputed"
          :versions="list"
          :active-phrases="currentPhrases"
          :compare-enabled="allowCompare"
          :download-link="current && current.link ? current.link : null"
          @toggle-versions="
            v => {
              if (v) {
                this.checkHighlightsForList();
              }
              versionsOpen = v;
              moreOpen = false;
            }
          "
          @toggle-comparison="v => (comparisonMode = v)"
          @toggle-more="
            v => {
              if (v) {
                highlightSearchInput = true;
              }
              versionsOpen = false;
              moreOpen = v;
            }
          "
          @search="
            v => {
              this.highlightSearchTyped = v;
              this.applyNewSearch();
            }
          "
          @download="downloadVersion"
        >
          <template #versionDate>{{ current.date }}</template>
        </text-versions-navigation>
      </template>
      <button
        @click="closeDrawer"
        class="versions__close"
        :class="{ 'versions__close--active': versionsOpen || moreOpen }"
      ></button>
    </base-modal>
  </div>
</template>

<script>
import TextVersionsNavigation from '@/components/Item/components/TextVersionsNavigation.vue';
import {
  mapTextWorker,
  mapTextWorker2,
  mapTextWorker3,
  mapTextWorker4,
  mapTextWorker5,
  mapTextWorker6,
  mapTextWorker7,
  mapTextWorker8,
} from '@/worker-api';
import { mapActions } from 'vuex';
import TextVersionsComparison from '@/components/Item/components/TextVersionsComparison.vue';
export default {
  props: {
    api: {
      lastVersion: String,
      version: String,
    },
    item: {
      Object,
      required: true,
    },
    highlights: {
      type: Array,
      default() {
        return [];
      },
    },
    highlightSearch: {
      type: String,
      default: '',
    },
  },
  components: {
    TextVersionsNavigation,
    TextVersionsComparison,
  },
  data() {
    return {
      currentPhrases: 'monitored',
      loading: true,
      loadingVersion: false,
      open: false,
      list: [],
      current: null,
      currentTextRaw: [],
      versionsOpen: false,
      moreOpen: false,
      dragStartX: 0,
      dragCurrentX: 0,
      dragging: false,
      highlightsCount: 0,
      highlightsCounts: null,
      highlightsCountsShowAll: false,
      highlightSearchCount: 0,
      highlightSearchTyped: '',
      highlightSearchInput: false,
      navigator: null,
      activeHighlight: null,
      sortHighlightsBy: 'amount',
      sortHighlightsDirection: 'desc',
      recalculatingHighlightsTimeout: null,
      initialized: false,
      availableWorker: 0,
      comparisonMode: false,
    };
  },
  computed: {
    allowCompare() {
      return (
        this.list &&
        this.list.length > 1 &&
        ['lex', 'regulations', 'healthfund', 'european_union_acts'].includes(
          this.$store.state.list.api.list
        )
      );
    },
    empty() {
      return this.list.length === 0;
    },
    highlightSearchComputed() {
      return this.highlightSearchTyped || this.highlightSearch;
    },
    sortedHighlightsCounts() {
      if (!this.highlightsCounts) {
        return [];
      }
      return this.highlightsCounts
        .slice()
        .sort((a, b) => {
          if (this.sortHighlightsBy === 'amount') {
            return this.sortHighlightsDirection === 'asc'
              ? a.count - b.count
              : b.count - a.count;
          } else if (this.sortHighlightsBy === 'alphabet') {
            return this.sortHighlightsDirection === 'asc'
              ? b.text.localeCompare(a.text)
              : a.text.localeCompare(b.text);
          }
        })
        .slice(
          0,
          this.highlightsCountsShowAll ? this.highlightsCounts.length : 4
        );
    },
  },
  created() {
    if (this.$route.query.last_text_open) {
      this.open = true;
    }
    if (this.highlightSearch) {
      this.highlightSearchTyped = this.highlightSearch;
      this.currentPhrases = 'search';
    }
    this.fetchData();
  },
  watch: {
    comparisonMode: function(v) {
      if (v) {
        this.$refs.baseModalRef.disableScroll();
      } else {
        this.$refs.baseModalRef.enableScroll();
      }
    },
    highlightSearchInput: function(v) {
      if (v) {
        setTimeout(() => {
          this.$refs.highlightSearchInput.$el.querySelector('input').focus();
        }, 140);
      }
    },
    navigator: {
      handler(v) {
        if (!v) {
          return;
        }
        const highlights = this.$refs.versionTextContainer.querySelectorAll(
          `[data-v-highlight-type="${v.type}"]`
        );
        highlights.forEach((element, index) => {
          element.classList.remove('highlight--active');
          // element.setAttribute('data-v-highlight-index', index);
          element.addEventListener('click', () => {
            this.navigator.current = index + 1;
          });
          if (
            element.getAttribute('data-v-highlight-type') === v.type &&
            index + 1 === v.current
          ) {
            element.classList.add('highlight--active');
            this.$nextTick(() => {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            });
          }
        });
      },
      deep: true,
    },
    id() {
      this.fetchData();
    },
    highlights: {
      immediate: true,
      handler: function() {
        // if (this.loading) {
        //   return;
        // }
        // const { text, highlightsCount } = this.mapText(
        //   this.currentTextRaw,
        //   this.highlights,
        //   this.highlightSearchComputed,
        //   true
        // );
        // this.current = {
        //   ...this.current,
        //   text: this.$util.replaceNewlines(text),
        //   highlightsCount,
        // };
      },
    },
    // highlightsCounts: {
    //   handler(highlights, old) {
    //     if (old) {
    //       const { text, highlightsCount } = this.mapText(
    //         this.currentTextRaw,
    //         highlights.filter(item => item.active).map(item => item.tag),
    //         this.highlightSearchComputed,
    //         true
    //       );
    //       this.current = {
    //         ...this.current,
    //         text: this.$util.replaceNewlines(text),
    //         highlightsCount,
    //       };
    //     }
    //   },
    //   deep: true,
    // },
  },
  methods: {
    ...mapActions('localAPI', ['mapTextAPI']),
    closeModal() {
      if (
        document.activeElement &&
        document.activeElement.tagName === 'INPUT'
      ) {
        return;
      }
      if (this.comparisonMode) {
        this.comparisonMode = false;
      } else {
        this.open = false;
      }
    },
    downloadVersion() {
      window.open(this.current.link, '_blank');
    },
    toggleSearchInput() {
      if (this.$store.getters['user/isLoggedIn']) {
        this.highlightSearchInput = true;
      } else {
        this.open = false;
        setTimeout(() => {
          this.$store.commit('openGuestModal', true);
        }, 300);
      }
    },
    recalculateHighlights() {
      if (this.recalculatingHighlightsTimeout) {
        clearTimeout(this.recalculatingHighlightsTimeout);
      }
      this.recalculatingHighlightsTimeout = setTimeout(async () => {
        mapTextWorker({
          texts: this.currentTextRaw,
          highlightWords: this.highlightsCounts
            .filter(item => item.active)
            .map(item => item.tag),
          highlightSearch: this.highlightSearchComputed,
        }).then(response => {
          this.current = {
            ...this.current,
            text: this.$util.replaceNewlines(response.text),
            highlightsCount: response.highlightsCount,
          };
          this.highlightsCount = response.highlightsCount;
          this.recalculatingHighlightsTimeout = null;
          // this.$set(
          //   this.list[index],
          //   'currentTextRaw',
          //   data.get_all_provisions_list
          // );
          //
          // console.log(data);
          // console.log(this.list[index]);
          //
          // this.$set(this.list[index], 'current', {
          //   id: this.list[index].id,
          //   title: this.$util.replaceNewlines(data.version_header),
          //   link: data.link,
          //   linkFormat: this.list[index].linkFormat,
          //   text: this.$util.replaceNewlines(response.text),
          //   highlightSearchCounts: response.highlightSearchCounts,
          //   highlightsCount: response.highlightsCount,
          //   highlightsCounts: response.highlightsCounts,
          //   highlightsAmount: response.highlightsAmount,
          // });
          //
          // if (current) {
          //   this.current = this.list[index].current;
          //   this.highlightsCounts = this.list[index].current.highlightsCounts;
          //   this.highlightsCount = response.highlightsAmount;
          //   this.highlightSearchCount = response.highlightSearchCounts;
          // }
        });
      }, 0);
      // const { text, highlightsCount } = this.mapText(
      //   this.currentTextRaw,
      //   this.highlightsCounts.filter(item => item.active).map(item => item.tag),
      //   this.highlightSearchComputed,
      //   true,
      //   true
      // );
      // this.current = {
      //   ...this.current,
      //   text: this.$util.replaceNewlines(text),
      //   highlightsCount,
      // };
    },
    unselectAllHighlights() {
      this.highlightsCounts = this.highlightsCounts.map(item => ({
        ...item,
        active: false,
      }));
      this.recalculateHighlights();
    },
    selectAllHighlights() {
      this.highlightsCounts = this.highlightsCounts.map(item => ({
        ...item,
        active: true,
      }));
      this.recalculateHighlights();
    },
    async applyNewSearch() {
      this.currentPhrases = 'search';
      // const { text, highlightsCount } = this.mapText(
      //   this.currentTextRaw,
      //   this.highlightsCounts.filter(item => item.active).map(item => item.tag),
      //   this.highlightSearchComputed,
      //   true
      // );
      // this.current = {
      //   ...this.current,
      //   text: this.$util.replaceNewlines(text),
      //   highlightsCount,
      // };
      this.recalculatingHighlightsTimeout = true;
      await this.checkHighlightsForIndex({
        index: this.list.findIndex(item => item.id === this.current.id),
        current: true,
      });
      this.recalculatingHighlightsTimeout = false;
      this.checkHighlightsForList({ force: true });
    },
    closeDrawer() {
      this.versionsOpen = false;
      this.moreOpen = false;
      this.highlightSearchInput = false;
    },
    mapText(
      texts,
      highlightWords,
      highlightSearch,
      current,
      avoidOverrideHighlights = false
    ) {
      /* eslint-disable */
      const highlightsCounts = [];
      let highlightsCount = 0;
      let highlightSearchCounts = 0;
      let highlightIndex = 0;
      const highlights = texts
        .map(({ text }) => {
          let highlightInfo = this.$util.markHighlightsWithCount(
            text,
            highlightWords,
            highlightIndex,
            highlightSearch
          );

          highlightsCount += highlightInfo.counts
            ? Object.values(highlightInfo.counts).reduce(
                (sum, value) => sum + value,
                0
              )
            : 0;

          highlightSearchCounts += highlightInfo.countSearch;

          if (highlightInfo.indexes && highlightInfo.indexes.length > 0) {
            highlightIndex =
              highlightInfo.indexes[highlightInfo.indexes.length - 1] + 1;
          }

          if (
            highlightInfo.counts &&
            Object.entries(highlightInfo.counts).length > 0
          ) {
            for (let tag in highlightInfo.counts) {
              const tagIndex = highlightsCounts.findIndex(
                item => item.tag === tag
              );
              if (tagIndex >= 0) {
                highlightsCounts[tagIndex].count =
                  highlightsCounts[tagIndex].count + highlightInfo.counts[tag];
              } else {
                highlightsCounts.push({
                  count: highlightInfo.counts[tag],
                  active: true,
                  tag,
                  text: tag.replaceAll('\b', ''),
                });
              }
            }
          }
          return `<p class="paragraph-hover text-justify">${highlightInfo.text}</div>`;
        })
        .join('');

      if (current) {
        if (!avoidOverrideHighlights) {
          highlightsCounts.sort((a, b) => b.count - a.count);
          this.highlightsCounts = highlightsCounts;
        }
        this.highlightsCount = highlightIndex;
        this.highlightSearchCount = highlightSearchCounts;
      }
      return {
        text: highlights,
        highlightsCounts,
        highlightSearchCounts,
        highlightsCount,
      };
    },
    async fetchData() {
      this.loading = true;
      const { data } = await this.$api.get(
        `${this.api.lastVersion}/${this.item.id}/`
      );
      this.list = data.list_of_other_versions
        ? data.list_of_other_versions.map(version => ({
            id: version.pk,
            date: new Date(version.date).toLocaleDateString('pl'),
            description: version.description,
            linkFormat: version.format,
            link: version.link,
          }))
        : [];

      if (this.list.length === 0) {
        this.loading = false;
        return;
      } else {
        (async () => {
          await this.checkHighlightsForIndex({ index: 0, current: true });
          this.initialized = true;
        })();
      }

      this.current = {
        id: this.list[0].id,
        title: data.last_version_header.replaceAll('/n', ''),
        format: data.last_version_format,
        link: data.last_version_download_button_link,
        linkFormat: data.last_version_format,
        text: data.list_of_last_version_provisions
          .map(
            item => `<p class="paragraph-hover text-justify">${item.text}</p>`
          )
          .join(''),
        date: this.list[0].date,
      };

      this.currentTextRaw = data.list_of_last_version_provisions;
      this.loading = false;
    },

    async checkHighlightsForIndex({ index, current = false }) {
      const { data } = await this.$api.get(
        `${this.api.version}/${this.list[index].id}/`
      );

      this.availableWorker++;

      const workers = [
        mapTextWorker2,
        mapTextWorker3,
        mapTextWorker4,
        mapTextWorker5,
        mapTextWorker6,
        mapTextWorker7,
        mapTextWorker8,
      ];

      if (this.availableWorker + 1 === workers.length) {
        this.availableWorker = 0;
      }

      const workerFunction = current
        ? mapTextWorker
        : workers[this.availableWorker];

      await workerFunction({
        texts: data.get_all_provisions_list,
        highlightWords: this.highlights,
        highlightSearch: this.highlightSearchComputed,
      }).then(response => {
        this.$set(
          this.list[index],
          'currentTextRaw',
          data.get_all_provisions_list
        );
        this.$set(this.list[index], 'current', {
          id: this.list[index].id,
          title: this.$util.replaceNewlines(data.version_header),
          link: data.link,
          linkFormat: this.list[index].linkFormat,
          text: this.$util.replaceNewlines(response.text),
          highlightSearchCounts: response.highlightSearchCounts,
          highlightsCount: response.highlightsCount,
          highlightsCounts: response.highlightsCounts,
          highlightsAmount: response.highlightsAmount,
          date: this.list[index].date,
        });

        if (current) {
          this.current = this.list[index].current;
          this.highlightsCounts = this.list[index].current.highlightsCounts;
          this.highlightsCount = response.highlightsAmount;
          this.highlightSearchCount = response.highlightSearchCounts;
        }
      });
    },

    checkHighlightsForList({ force = false } = {}) {
      for (let i = 0; i < this.list.length; i++) {
        if (!this.list[i].current || force) {
          delete this.list[i].current;
          this.checkHighlightsForIndex({ index: i });
        }
      }
    },
    async loadVersion(id) {
      this.closeDrawer();
      const newCurrentVersion = this.list.find(item => item.id === id);
      if (!newCurrentVersion) {
        return;
      }
      newCurrentVersion.current.linkFormat = newCurrentVersion.linkFormat;
      this.current = newCurrentVersion.current;
      this.current.date = newCurrentVersion.date;
      this.currentTextRaw = newCurrentVersion.currentTextRaw;

      this.highlightsCounts = newCurrentVersion.current.highlightsCounts;
      this.highlightsCount = newCurrentVersion.current.highlightsAmount;
      this.highlightSearchCount =
        newCurrentVersion.current.highlightSearchCounts;

      // let { text, highlightsCount, highlightsCounts } = this.mapText(
      //   newCurrentVersion.currentTextRaw,
      //   this.highlights,
      //   this.highlightSearchComputed,
      //   true
      // );
      // this.currentTextRaw = newCurrentVersion.currentTextRaw;
      // this.current = {
      //   id,
      //   title: this.$util.replaceNewlines(newCurrentVersion.description),
      //   link: newCurrentVersion.link,
      //   text: this.$util.replaceNewlines(text),
      //   linkFormat: newCurrentVersion.linkFormat,
      //   highlightsCount,
      //   highlightsCounts,
      // };
      // this.highlightsCounts = highlightsCounts;
      this.$refs.baseModalRef.scrollTop();
    },
    onTouchStart(evt) {
      this.dragStartX = evt.touches[0].pageX;
      this.dragCurrentX = this.dragStartX;
      this.dragging = true;
      requestAnimationFrame(this.update);
    },
    onTouchMove(evt) {
      if (!this.dragging) {
        return;
      }
      this.dragCurrentX = evt.touches[0].pageX;
    },
    onTouchEnd(evt) {
      if (!this.dragging) {
        return;
      }
      this.dragging = false;
      const translateX = Math.max(0, this.dragCurrentX - this.dragStartX);
      this.$refs.versions.style.transform = '';

      if (translateX > 20) {
        this.closeDrawer();
      }
    },
    update() {
      if (!this.dragging) {
        return;
      }
      requestAnimationFrame(this.update);
      const translateX = Math.max(0, this.dragCurrentX - this.dragStartX);
      this.$refs.versions.style.transform = `translateX(${translateX}px)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.versions-header {
  //max-width: 36rem;
}
.versions-header__download {
  margin-right: 1rem;
}

.project-title {
  color: $gray-3;
  font-weight: bold;
  font-size: 0.8rem;
}

.title {
  flex: 1;
  font-size: 1.28rem;
  font-weight: bold;
  color: $primary;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 6px;
  img {
    width: 18px;
    height: 18px;
  }
}
.version-title {
  margin-top: 0.85rem;
  margin-right: 3rem;
}

.versions-tags {
  display: flex;
  gap: 10px;
  @include mq('tablet') {
    flex-wrap: wrap;
    flex-direction: row;
  }
  flex-direction: column;
  align-items: start;
  .versions-tag {
    min-height: 33px;
    white-space: nowrap;
    border-radius: 27px;
    padding: 4px 12px;
    background-color: rgba(216, 216, 216, 0.5);
    color: #363734;
    font-size: 12px;
    cursor: pointer;
    -webkit-transition: color 50ms ease-in-out;
    transition: color 50ms ease-in-out;
    display: flex;
    gap: 6px;
    align-items: center;
    user-select: none;
    span {
      background-color: rgba(216, 216, 216, 0.5);
      height: 25px;
      min-width: 25px;
      padding-inline: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
    }
    &--active {
      background-color: #fff;
      color: #0042ff;
      -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    }
    &--count {
      padding-right: 4px;
    }
  }
}

.versions {
  z-index: 11;
  position: absolute;
  top: -3px;
  bottom: -3px;
  right: -3px;
  width: 30rem;
  max-width: 102%;
  //overflow: hidden;
  text-align: center;
  pointer-events: none;
  transition: transform 0.15s $easeOutQuad;

  &--open {
    pointer-events: auto;

    .versions__inner {
      opacity: 1;
      transform: translateY(0);
      transition: transform 0.3s $easeOutQuad;
      z-index: auto;
    }
  }
}
.versions__sticky {
  position: sticky;
  top: 0;
}
.versions--open {
  .versions__inner {
    height: 100%;
    background-color: white;
  }
}
.versions__inner {
  opacity: 0;
  z-index: -1000;
  //overflow: auto;
  padding: 3rem 1.5rem 2rem;
  border-radius: 1.5rem;
  border: 3px solid $gray-4;
  transform: translateY(100vh);
  transition: opacity 0.5s ease-in-out, transform 0.5s $easeOutQuad;
}
.versions__close-btn {
  padding: 1rem;
}
.versions__close {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $gray-5;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 1;

  &--active {
    opacity: 0.6;
    pointer-events: auto;

    &:hover {
      opacity: 0.4;
    }
  }
}

.version-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 1.5rem 0.9rem;
  border: 2px solid $gray-5;
  border-radius: 0.4rem;
  font-size: 1.14rem;
  text-align: left;
  color: $gray-1;
  transition: background-color 0.15s;
  position: relative;

  @include mq('phone') {
    display: flex;
  }

  &.active,
  &:hover {
    border-color: $primary;
  }
  &:disabled {
    > div.d-flex {
      opacity: 0.2;
    }
  }

  .lens-icon {
    position: absolute;
    top: 13px;
    right: -14px;
    background-color: white;
    padding-top: 1px;
    display: flex;
  }
}
.version-button__date {
  display: block;
  margin-right: 3rem;
}
.version-button__desc {
  font-weight: bold;
}
.version-handlers {
  display: flex;
  @include mq('tablet') {
    flex-direction: column;
    align-items: end;
  }
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
  color: $gray-2;
  &--unselect {
    white-space: nowrap;
    text-decoration: underline;
    cursor: pointer;
  }
  &--sorts {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    justify-content: end;
  }
  &--sort {
    cursor: pointer;
    &.active {
      color: $primary;
    }
    &:not(.active) {
      color: $gray-2;
    }
  }
}

.versions-top-container {
  display: flex;
  @include mq('tablet') {
    justify-content: space-between;
    flex-direction: row;
  }
  flex-direction: column-reverse;
}

.text-version-button-trigger {
  position: relative;
  .base-button__text {
    > div {
      position: absolute;
      height: 30px;
      width: 30px;
      background-color: white;
      border-radius: 100%;
      top: 50%;
      right: -18px;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
