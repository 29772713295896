import { mapLinkImage } from './mapLinkImage';

const STAGE_DONE = ['issued', 'senat_stage', 'journal', 'eurlex_promulgated', 'com_sec'];

export const groupLinks = (linksObj = {}) => {
  const links = Object.entries(linksObj).map(([name, value]) => ({
    name,
    value,
    image: mapLinkImage(name),
  }));
  const project = [];
  const done = [];
  links.forEach(link => {
    if (STAGE_DONE.includes(link.name)) {
      done.push(link);
      return;
    }
    project.push(link);
  });
  return { project, done };
};
