var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"flex-grow":"1","display":"flex","flex-direction":"column"}},[_c('portal',{attrs:{"to":_vm.isMobile ? 'buttons-sidebar' : 'comparison-navigation'}},[(_vm.isMobile && !_vm.isPortrait)?_c('div',{class:[
        'modal__close-sticky--compare',
        {
          'modal__close-sticky--compareSlided': !_vm.showTop,
        },
      ],on:{"click":function($event){return _vm.$emit('compare', false)}}},[_c('svg',{attrs:{"data-v-07ed370a":"","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"data-v-07ed370a":"","d":"M18 6L6 18","stroke":"currentColor","stroke-width":"3","stroke-linecap":"round","stroke-linejoin":"round"}}),_vm._v(" "),_c('path',{attrs:{"data-v-07ed370a":"","d":"M6 6L18 18","stroke":"currentColor","stroke-width":"3","stroke-linecap":"round","stroke-linejoin":"round"}})])]):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"slide-left"}},[(_vm.navigationIndex && ((_vm.isMobile && !_vm.isPortrait) || !_vm.isMobile))?_c('div',{staticClass:"d-flex mb-2"},[_c('div',{class:[
            'text-version-comparison-navigation',
            {
              'text-version-comparison-navigation--mobile': _vm.isMobile,
              'text-version-comparison-navigation--hidden':
                _vm.isMobile && _vm.endIsNear,
            },
          ]},[_c('button',{on:{"click":function($event){_vm.navigationIndex > 1 ? _vm.navigationIndex-- : null}}},[_c('img',{attrs:{"src":require('@/assets/icons/ArrowUpNav.svg')}})]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.navigationIndex)+"/"+_vm._s(_vm.navigationSummary))]),_vm._v(" "),_c('button',{on:{"click":function($event){_vm.navigationIndex < _vm.navigationSummary ? _vm.navigationIndex++ : null}}},[_c('img',{attrs:{"src":require('@/assets/icons/ArrowDownNav.svg')}})])])]):_vm._e()])],1),_vm._v(" "),_c('div',{class:[
      'text-version-comparison',
      {
        'text-version-comparison--mobile': _vm.isMobile,
      },
    ]},[_c('div',{class:[
        'text-version-comparison__rotate',
        {
          'text-version-comparison__rotate--active': _vm.isMobile && _vm.isPortrait,
        },
      ]},[_c('img',{attrs:{"src":require('@/assets/icons/RotateDevice.svg')}}),_vm._v("\n      Obróć urządzenie aby porównać wersje dokumentów.\n    ")]),_vm._v(" "),_c('div',{staticClass:"text-version-comparison__grid"},[_c('BaseWrapperInjectElement',[_c('Transition',{attrs:{"name":"fade"}},[(_vm.showTop)?_c('text-versions-comparison-select',{attrs:{"options":_vm.leftTextOptions},on:{"toggled":v => (_vm.leftTextDisabled = v)},model:{value:(_vm.leftTextSelect),callback:function ($$v) {_vm.leftTextSelect=$$v},expression:"leftTextSelect"}}):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"text-version-comparison__arrow"},[_c('BaseWrapperInjectElement',[_c('Transition',{attrs:{"name":"fade"}},[(_vm.showTop)?_c('img',{attrs:{"src":require("@/assets/icons/ArrowRightAlt.svg"),"alt":""}}):_vm._e()])],1)],1),_vm._v(" "),_c('div',[_c('BaseWrapperInjectElement',[_c('Transition',{attrs:{"name":"fade"}},[(_vm.showTop)?_c('text-versions-comparison-select',{attrs:{"options":_vm.rightTextOptions},on:{"toggled":v => (_vm.rightTextDisabled = v)},model:{value:(_vm.rightTextSelect),callback:function ($$v) {_vm.rightTextSelect=$$v},expression:"rightTextSelect"}}):_vm._e()],1)],1)],1),_vm._v(" "),(_vm.indenticalTexts)?_c('div',{staticClass:"text-version-comparison__identical"},[_c('div',[_vm._v("Porównywane wersje są identyczne")])]):_vm._e(),_vm._v(" "),(_vm.fetching)?_c('div',{staticClass:"text-version-comparison__spinner"},[_c('BaseSpinner')],1):[_c('div',{ref:"leftTextContainer",class:[
            'text-version-comparison--text',
            {
              'text-version-comparison--textMobile': _vm.isMobile,
            },
          ],style:(`--height: ${
              _vm.leftTextContainerScroll ? `${_vm.leftTextContainerScroll}px` : null
            };`),attrs:{"id":"text-version-comparison-left"},domProps:{"innerHTML":_vm._s(_vm.leftText)},on:{"scroll":_vm.handleMovedText}}),_vm._v(" "),_c('div',{staticClass:"text-version-comparison__line",style:(`--items-count: ${_vm.moves.filter(item => item.visible).length};`)},_vm._l((_vm.moves),function(move,i){return _c('div',{key:`move-${i}`,class:[
              `text-version-comparison__lineItem text-version-comparison__lineItem--${
                move.offsetTo > move.offsetFrom ? move.sideFrom : move.sideTo
              }`,
              {
                'text-version-comparison__lineItem--close':
                  move.offsetDistanceY < 15 && move.offsetDistanceY > -15,
                'text-version-comparison__lineItem--hide':
                  _vm.moveHoveredIndex && _vm.moveHoveredIndex !== move.moveNumber,
                'text-version-comparison__lineItem--hover':
                  _vm.moveHoveredIndex && _vm.moveHoveredIndex === move.moveNumber,
              },
            ],style:(`--offset-top: ${
                move.offsetTo > move.offsetFrom
                  ? move.offsetFrom
                  : move.offsetTo
              }%; --offset-bottom: ${
                move.offsetTo <= move.offsetFrom
                  ? move.offsetFrom
                  : move.offsetTo
              }%;     --items-index: ${_vm.moves
                .filter(item => item.visible)
                .indexOf(move)}; opacity: ${move.visible ? 1 : 0}; ${
                move.offsetDistanceY < 15 && move.offsetDistanceY > -15
                  ? `--height-divs: ${move.offsetDistanceY * 2 + 2}px`
                  : ''
              }`),on:{"mouseover":function($event){_vm.moveHoveredIndex = move.moveNumber},"mouseleave":function($event){_vm.moveHoveredIndex = null}}},[_c('div',{style:(move.offsetDistanceY < 15 && move.offsetDistanceY > -15
                  ? `height: ${move.offsetDistanceY * 2 + 2}px`
                  : '')}),_vm._v(" "),_c('div',{style:(move.offsetDistanceY < 15 && move.offsetDistanceY > -15
                  ? `height: ${move.offsetDistanceY * 2 + 2}px`
                  : '')})])}),0),_vm._v(" "),_c('div',{ref:"rightTextContainer",class:[
            'text-version-comparison--text',
            {
              'text-version-comparison--textMobile': _vm.isMobile,
            },
          ],style:(`--height: ${
              _vm.rightTextContainerScroll
                ? `${_vm.rightTextContainerScroll}px`
                : null
            }; ${_vm.indenticalTexts ? `opacity: .4` : ''}`),attrs:{"id":"text-version-comparison-right"},domProps:{"innerHTML":_vm._s(_vm.rightText)},on:{"scroll":_vm.handleMovedText}})]],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }