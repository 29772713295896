import { markHighlights, replaceNewlines } from '../util';

export const prepareInfo = (data, highlights, descriptions = null) => {
  const info = [];
  const highlightWords = highlights.description_keywords_list;
  const highlightSearch =
    highlights.full_text_keywords_search_list &&
    highlights.full_text_keywords_search_list.length === 1
      ? highlights.full_text_keywords_search_list[0]
      : null;

  if (data.resign) {
    info.push({
      name: 'Przyczyny rezygnacji z prac nad projektem',
      value: replaceNewlines(data.resign),
      resigned: true,
      open: false,
      highlightsTag: false,
      highlightsSearch: false,
      versions: [],
      highlightWordsRaw: highlightWords,
      highlightSearchRaw: highlightSearch,
      itemKey: 'resign',
      itemId: data.pk,
    });
  }
  if (data.description_sejm) {
    info.push({
      name: 'Skrócony opis rozwiązań ujętych w projekcie',
      value: markHighlights(
        replaceNewlines(data.description_sejm),
        highlightWords,
        highlightSearch
      ),
      open: false,
      highlights: Boolean(highlights.sejm_description_contains_keywords),
      highlightsTag: Boolean(highlights.sejm_description_contains_keywords),
      highlightsSearch: Boolean(
        highlights.sejm_description_contains_search_keywords
      ),
      // versions: setVersions(
      //   descriptions.list_of_other_descriptions.description_sejm_list,
      //   highlightWords,
      //   highlightSearch
      // ),
      versions: [],
      versionsRaw:
        descriptions.list_of_other_descriptions.description_sejm_list,
      highlightWordsRaw: highlightWords,
      highlightSearchRaw: highlightSearch,
      itemKey: 'description_sejm',
      itemId: data.pk,
    });
  }
  if (data.description_gov_decision) {
    info.push({
      name: 'Uzasadnienie decyzji rządu',
      value: markHighlights(
        replaceNewlines(data.description_gov_decision),
        highlightWords,
        highlightSearch
      ),
      open: false,
      highlights: Boolean(highlights.gov_description_contains_keywords),
      highlightsTag: Boolean(highlights.gov_description_contains_keywords),
      highlightsSearch: Boolean(
        highlights.gov_description_contains_search_keywords
      ),
      // versions: setVersions(
      //   descriptions.list_of_other_descriptions.description_gov_decision_list,
      //   highlightWords,
      //   highlightSearch
      // ),
      versions: [],
      versionsRaw:
        descriptions.list_of_other_descriptions.description_gov_decision_list,
      highlightWordsRaw: highlightWords,
      highlightSearchRaw: highlightSearch,
      itemKey: 'description_gov_decision',
      itemId: data.pk,
    });
  }
  if (data.needs) {
    info.push({
      name: 'Przyczyny i potrzeba wprowadzenia',
      value: markHighlights(
        replaceNewlines(data.needs),
        highlightWords,
        highlightSearch
      ),
      open: false,
      highlights: Boolean(highlights.kprm_needs_contains_keywords),
      highlightsTag: Boolean(highlights.kprm_needs_contains_keywords),
      highlightsSearch: Boolean(highlights.kprm_needs_contains_search_keywords),
      // versions: setVersions(
      //   descriptions.list_of_other_descriptions.needs_list,
      //   highlightWords,
      //   highlightSearch
      // ),
      versions: [],
      versionsRaw: descriptions.list_of_other_descriptions.needs_list,
      highlightWordsRaw: highlightWords,
      highlightSearchRaw: highlightSearch,
      itemKey: 'needs',
      itemId: data.pk,
    });
  }
  if (data.solutions) {
    info.push({
      name: 'Istota rozwiązań ujętych w projekcie',
      value: markHighlights(
        replaceNewlines(data.solutions),
        highlightWords,
        highlightSearch
      ),
      open: false,
      highlights: Boolean(highlights.kprm_solutions_contains_keywords),
      highlightsTag: Boolean(highlights.kprm_solutions_contains_keywords),
      highlightsSearch: Boolean(
        highlights.kprm_solutions_contains_search_keywords
      ),
      versions: [],
      // versions: setVersions(
      //   descriptions.list_of_other_descriptions.solutions_list,
      //   highlightWords,
      //   highlightSearch
      // ),
      versionsRaw: descriptions.list_of_other_descriptions.solutions_list,
      highlightWordsRaw: highlightWords,
      highlightSearchRaw: highlightSearch,
      itemKey: 'solutions',
      itemId: data.pk,
    });
  }
  if (data.motives) {
    info.push({
      name: 'Uzasadnienie',
      value: markHighlights(
        replaceNewlines(data.motives),
        highlightWords,
        highlightSearch
      ),
      open: false,
      highlights: Boolean(highlights.motives_contains_keywords),
      highlightsTag: Boolean(highlights.motives_contains_keywords),
      highlightsSearch: Boolean(highlights.motives_contains_search_keywords),
      // versions: setVersions(
      //   descriptions.list_of_other_descriptions.motives_list,
      //   highlightWords,
      //   highlightSearch
      // ),
      versions: [],
      versionsRaw: descriptions.list_of_other_descriptions.motives_list,
      highlightWordsRaw: highlightWords,
      highlightSearchRaw: highlightSearch,
      itemKey: 'motives',
      itemId: data.pk,
    });
  }
  if (data.consultation_summary) {
    info.push({
      name: 'Streszczenie',
      value: markHighlights(
        replaceNewlines(data.consultation_summary),
        highlightWords,
        highlightSearch
      ),
      open: false,
      highlights: Boolean(highlights.consultation_summary_contains_keywords),
      highlightsTag: Boolean(highlights.consultation_summary_contains_keywords),
      highlightsSearch: Boolean(
        highlights.consultation_summary_contains_search_keywords
      ),
      versions: [],
      highlightWordsRaw: highlightWords,
      highlightSearchRaw: highlightSearch,
      itemKey: 'consultation_summary',
      itemId: data.pk,
    });
  }
  if (data.proposal_summary) {
    info.push({
      name: 'Streszczenie wniosku (ENG)',
      value: markHighlights(
        replaceNewlines(data.proposal_summary),
        highlightWords,
        highlightSearch
      ),
      open: false,
      highlights: Boolean(highlights.proposal_summary_contains_keywords),
      highlightsTag: Boolean(highlights.proposal_summary_contains_keywords),
      highlightsSearch: Boolean(
        highlights.proposal_summary_contains_search_keywords
      ),
      versions: [],
      highlightWordsRaw: highlightWords,
      highlightSearchRaw: highlightSearch,
      itemKey: 'proposal_summary',
      itemId: data.pk,
    });
  }
  return info;
};

/* eslint-disable */
export const setVersions = (versions, highlightWords, highlightSearch) => {
  versions.forEach((item, index) => {
    const result = markHighlights(
      replaceNewlines(item.content),
      highlightWords,
      highlightSearch,
      true
    );
    item.originalContent = replaceNewlines(item.content);
    item.content = result.out ? result.out : result;
    item.highlightWords = result.markedTextCounts
      ? result.markedTextCounts
      : null;
    item.highlightSearch = highlightSearch;
    item.highlightSearchCounts = result.matchHighlightSearchCount;
    const parser = new DOMParser();
    const doc = parser.parseFromString(item.content, 'text/html');
    const monitoredAttributes = doc.querySelectorAll(
      '[data-v-highlight-type="monitored"]'
    );
    // monitoredAttributes.forEach(element => {
    //   let word = element.getAttribute('data-v-highlight-word');
    //   if (!item.highlightWords.find(item => item.word === word)) {
    //     item.highlightWords.push({ word, count: 0, active: true });
    //   }
    //   item.highlightWords.find(item => item.word === word).count++;
    // });
    const searchAttributes = doc.querySelectorAll(
      '[data-v-highlight-type="search"]'
    );
    item.highlightSearchCounts = searchAttributes.length;
    item.highlightsCount = monitoredAttributes.length;
    item.date = formatDate(item);
    item.id = index + 1;
  });

  return versions;
};

const formatDate = item => {
  let date = new Date(item.date);
  if (isNaN(date)) {
    return item.date;
  }

  let day = String(date.getDate()).padStart(2, '0');
  let month = String(date.getMonth() + 1).padStart(2, '0'); // Miesiące są indeksowane od 0
  let year = date.getFullYear();

  return `${day}.${month}.${year}`;
};
