<template>
  <div
    class="timeline"
    :class="{ 'timeline--high': timeline.length >= 4 }"
    ref="container"
  >
    <button
      @click="scroll('up')"
      class="timeline__up"
      :class="{ 'timeline__btn--hidden': visibleStart === 0 }"
    ></button>
    <ul
      class="timeline__list"
      ref="list"
      :style="{ transform: `translateY(${-top}px)` }"
    >
      <li
        v-for="(item, index) in timeline"
        :key="index"
        ref="items"
        class="timeline__item"
        :class="{
          'timeline__item--hidden': index < visibleStart || index > visibleEnd,
        }"
      >
        <span class="timeline__date">{{ item.date }}</span>
        <span class="timeline__desc">{{ item.description }}</span>
      </li>
    </ul>
    <button
      @click="scroll('down')"
      class="timeline__down"
      :class="{
        'timeline__btn--hidden': visibleEnd >= timeline.length,
      }"
    ></button>
  </div>
</template>

<script>
export default {
  props: {
    timeline: Array,
  },
  data() {
    return {
      top: 0,
      visibleStart: 0,
      visibleEnd: 0,
      scrollCount: 0,
    };
  },
  watch: {
    timeline() {
      this.setInitial();
    },
  },
  mounted() {
    this.setInitial();
    if (window.ResizeObserver) {
      const resizeObserver = new ResizeObserver(() => {
        const scrollCount = this.scrollCount;
        for (let i = 0; i < scrollCount; i++) {
          this.scroll('up');
        }
        this.setInitial();
        for (let i = 0; i < scrollCount; i++) {
          this.scroll('down');
        }
      });
      resizeObserver.observe(this.$refs.container);
    }
  },
  methods: {
    setInitial() {
      if (!this.$refs.container) {
        return;
      }
      this.containerHeight = this.$refs.container.offsetHeight - 42;
      this.visibleEnd = this.lastIndexVisible(0);
    },
    lastIndexVisible(startIndex) {
      const itemsCount = this.$refs.items.length;
      let totalHeight = 0;
      for (let index = startIndex; index < itemsCount; index++) {
        const item = this.$refs.items[index];
        totalHeight += item.clientHeight;
        if (totalHeight > this.containerHeight) {
          return index - 1;
        }
      }
      return itemsCount;
    },
    distanceFromTop(uptoIndex) {
      let totalHeight = 0;
      for (let index = 0; index < uptoIndex; index++) {
        const item = this.$refs.items[index];
        totalHeight += item.clientHeight;
      }
      return totalHeight;
    },
    scroll(direction) {
      const itemsCount = this.$refs.items.length;
      const visibleCount = this.visibleEnd - this.visibleStart;
      const moveBy =
        direction === 'down'
          ? Math.min(visibleCount, itemsCount - 1 - this.visibleEnd)
          : -Math.min(visibleCount, this.visibleStart);
      this.visibleStart += moveBy;
      this.visibleEnd = this.lastIndexVisible(this.visibleStart);
      this.top = this.distanceFromTop(this.visibleStart);
      if (direction === 'up') {
        this.scrollCount--;
      } else if (direction === 'down') {
        this.scrollCount++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  position: relative;
  overflow: hidden;
  height: 100%;
}
.timeline--high {
  height: 24rem;

  @include mq('tablet') {
    height: 100%;
  }
  .timeline__list {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transition: transform 0.4s $easeOutCubic;
  }
}
.timeline__list {
  padding: 1.5rem 0;

  &::before {
    content: '';
    position: absolute;
    left: 7rem;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: white;
  }
}
.timeline__item {
  position: relative;
  display: flex;
  padding-bottom: 1rem;
  transition: opacity 0.3s;

  &::before {
    content: '';
    position: absolute;
    left: 7rem;
    top: 0.25rem;
    width: 4px;
    height: 4px;
    margin-left: -5px;
    border-radius: 50%;
    border: 4px solid white;
    background-color: $primary;
  }

  &:first-child {
    color: $primary;
    font-weight: bold;

    &::before {
      border-color: $primary;
      background-color: $secondary;
    }
  }
  &:last-child {
    padding-bottom: 0;
  }
}
.timeline__item--hidden {
  opacity: 0;
}

.timeline__up,
.timeline__down {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  height: 2rem;
  width: 100%;

  &::after {
    position: absolute;
    left: 7rem;
    margin-left: -0.3rem;
  }
  &:hover {
    &::after {
      border-color: $secondary;
    }
  }
}
.timeline__up {
  top: 0;
  &::after {
    @include arrowPseudo($primary, 'up');
    top: 0.3rem;
  }
}
.timeline__down {
  bottom: 0;
  &::after {
    @include arrowPseudo($primary, 'down');
    bottom: 0.3rem;
  }
}
.timeline__btn--hidden {
  opacity: 0;
  pointer-events: none;
}

.timeline__date {
  width: 9rem;
}
.timeline__desc {
  flex: 1;
}
</style>
