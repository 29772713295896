export const mapLinkImage = name => {
  switch (name) {
    case 'draft':
    case 'issued':
      return 'nfz';
    case 'senat_draft':
    case 'senat_stage':
      return 'senat';
    default:
      return name;
  }
};
