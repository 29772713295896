<template>
  <div class="monitored" :class="{ hidden: !active }">
    <div class="inner row row--no-gutters row--vcenter">
      <div class="info">
        <base-tooltip position="left" position-tablet="right" wider>
          <template #text>
            <p><strong>Czym są “Monitorowane przepisy”</strong></p>
            <p>
              Znajdziesz tu przepisy zawierające słowa kluczowe przypisane do
              każdego z Twoich modułów abyś mógł szybko zapoznać się z istotnymi
              dla Ciebie zmianami w wybranym projekcie.
            </p>
          </template>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="16"
            viewBox="0 0 15 16"
            @mouseover="bulbHovered = true"
            @mouseleave="bulbHovered = false"
          >
            <path
              :fill="bulbHovered ? '#0042FF' : '#9b9b9b'"
              fill-rule="nonzero"
              d="M9.24 13.597v.454a.721.721 0 0 1-.612.714l-.112.413a.514.514 0 0 1-.496.381H6.909a.514.514 0 0 1-.496-.38l-.11-.414a.724.724 0 0 1-.614-.717v-.455c0-.243.195-.438.439-.438H8.8a.442.442 0 0 1 .438.442zm2.058-6.128c0 1.034-.41 1.972-1.076 2.66a3.515 3.515 0 0 0-.941 1.909.634.634 0 0 1-.627.534h-2.38a.628.628 0 0 1-.623-.531 3.554 3.554 0 0 0-.948-1.915 3.817 3.817 0 0 1-1.07-2.615A3.822 3.822 0 0 1 7.44 3.637a3.83 3.83 0 0 1 3.858 3.832zm-3.4-2.321a.432.432 0 0 0-.432-.432A2.769 2.769 0 0 0 4.7 7.482a.432.432 0 0 0 .864 0c0-1.05.855-1.902 1.902-1.902a.43.43 0 0 0 .432-.432zm-.432-2.77a.432.432 0 0 0 .432-.432V.432a.432.432 0 0 0-.864 0v1.514c0 .24.195.433.432.433zM2.379 7.467a.432.432 0 0 0-.433-.432H.432a.432.432 0 0 0 0 .864h1.514a.43.43 0 0 0 .433-.432zm12.12-.432h-1.514a.432.432 0 0 0 0 .864H14.5a.432.432 0 0 0 0-.864zm-11.24 4.03l-1.072 1.073a.43.43 0 1 0 .608.611l1.072-1.072a.43.43 0 1 0-.608-.612zm8.11-7.069c.108 0 .22-.041.304-.124l1.072-1.073a.43.43 0 0 0 0-.611.43.43 0 0 0-.611 0L11.06 3.259a.43.43 0 0 0 .307.736zm-8.11-.128a.432.432 0 0 0 .608-.611L2.795 2.183a.43.43 0 0 0-.612 0 .43.43 0 0 0 0 .612L3.26 3.867zm8.414 7.197a.43.43 0 0 0-.612 0 .43.43 0 0 0 0 .612l1.073 1.072a.432.432 0 0 0 .608-.611l-1.07-1.073z"
            />
          </svg>
        </base-tooltip>
      </div>
      <div class="col-12 col-desktop-5 first-col mb-4 mb-desktop-0">
        <img src="./monitoring-icon.svg" alt="" />
        <p class="text">
          Monitoring przepisów według skonfigurowanych słów kluczowych
        </p>
      </div>
      <div class="col-12 col-desktop-7">
        <base-button
          @click="openModal"
          theme="secondary"
          :loading="loading"
          full
          class="button"
        >
          Monitorowane przepisy
        </base-button>
        <base-modal :open="open" @close="open = false">
          <base-spinner v-if="loading" />
          <div v-else class="monitored-entries mb-8 text-justify">
            <div
              v-for="(entry, index) in items"
              :key="index"
              class="entry"
              :class="{
                favorite: entry.favorite,
                additional: !entry.favorite,
                'top-caret':
                  !entry.siblings.top &&
                  items[index - 1] &&
                  items[index - 1].favorite,
                'bottom-caret':
                  !entry.siblings.bottom &&
                  items[index + 1] &&
                  items[index + 1].favorite,
                'has-top-arrow': entry.siblings.top,
                'has-bottom-arrow': entry.siblings.bottom,
              }"
            >
              <p class="mb-0" v-html="entry.text" />

              <button
                v-if="entry.siblings.top || entry.siblings.bottom"
                @click="showSiblings(entry.index)"
                class="open"
              >
                <div class="open__top" v-if="entry.siblings.top">
                  <span class="open__inner">pokaż kontekst</span>
                </div>
                <div class="open__bottom" v-if="entry.siblings.bottom">
                  <span class="open__inner">pokaż kontekst</span>
                </div>
              </button>

              <base-button-exit
                v-if="!entry.favorite"
                @click="hideEntry(entry.index)"
                class="close"
                type="light"
              />
            </div>
          </div>
        </base-modal>
      </div>
    </div>
  </div>
</template>

<script>
const SHOW_DELAY = 2000;

export default {
  props: {
    api: String,
    itemId: Number,
    highlights: Array,
  },
  data() {
    return {
      active: !this.$store.getters['user/isLoggedIn'],
      loading: false,
      open: false,
      data: [],
      bulbHovered: false,
    };
  },
  computed: {
    items() {
      return this.data
        .map((entry, index, data) => ({
          ...entry,
          siblings: {
            top: data[index - 1] && !data[index - 1].visible,
            bottom: data[index + 1] && !data[index + 1].visible,
          },
        }))
        .filter(entry => entry.visible);
    },
  },
  created() {
    if (!this.$store.getters['user/isLoggedIn']) {
      return;
    }
    if (this.$route.query.monitored_provisions) {
      this.open = true;
    }
    this.fetchData();
  },
  watch: {
    highlights() {
      this.data = this.data.map(el => ({
        ...el,
        text: this.$util.markHighlights(el.text, this.highlights),
      }));
    },
  },
  methods: {
    openModal() {
      if (!this.$store.getters['user/isLoggedIn']) {
        this.$emit('open');
        return;
      }
      this.open = true;
    },
    async fetchData() {
      this.loading = true;
      const activeTimeout = setTimeout(() => {
        this.active = true;
      }, SHOW_DELAY);
      const { data } = await this.$api.get(`${this.api}/${this.itemId}`);
      clearTimeout(activeTimeout);
      this.data = data.map(({ text, favorite }, index) => ({
        index,
        text: this.$util.markHighlights(text, this.highlights),
        favorite,
        visible: favorite,
      }));
      if (this.data.length === 0) {
        this.active = false;
      } else {
        this.active = true;
      }
      this.loading = false;
    },
    showSiblings(index) {
      if (this.data[index - 1]) {
        this.data[index - 1].visible = true;
      }
      if (this.data[index + 1]) {
        this.data[index + 1].visible = true;
      }
    },
    hideEntry(index) {
      this.data[index].visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './Monitored';
</style>
