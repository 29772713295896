<template>
  <div>
    <base-accordion
      v-for="(info, infoIndex) in infos"
      :key="`info-${infoIndex}`"
      :open="info.open"
      :error="info.resigned"
      @toggle="toggleOpenInfo(infoIndex)"
      class="mb-4"
    >
      <template #title>
        <highlight-icon
          v-if="info.highlightsSearch || info.highlightsTag"
          :variant="
            info.highlightsTag
              ? info.highlightsSearch
                ? 'both'
                : 'primary'
              : 'secondary'
          "
        >
          <p>
            <strong>Uwaga.</strong> Opis zawiera słowa kluczowe z Twojego
            monitoringu.
          </p>
        </highlight-icon>
        {{ info.name }}
      </template>

      <info-list-show-text :open="info.open" :text="info" :title="title" />
      <p v-html="info.value" class="pr-12"></p>
    </base-accordion>

    <base-accordion
      v-for="(list, listIndex) in lists"
      :key="`list-${listIndex}`"
      :open="list.open"
      @toggle="toggleOpenList(listIndex)"
      class="mb-4"
    >
      <template #title>
        <highlight-icon
          v-if="list.highlightsSearch || list.highlightsTag"
          :variant="
            list.highlightsTag
              ? list.highlightsSearch
                ? 'both'
                : 'primary'
              : 'secondary'
          "
        >
          <p>
            <strong>Uwaga.</strong> Zmiany dotyczą
            {{ getTooltipPluralWord(list) || 'ustaw objętych' }} Twoim
            monitoringiem.
          </p>
        </highlight-icon>
        {{ list.name }}
      </template>

      <div
        v-for="(sublist, sublistIndex) in list.values"
        :key="`sublist-${sublistIndex}`"
        class="sublist"
      >
        <div
          v-if="sublist.paragraphs.length === 0"
          class="sublist-item sublist-item--title-only"
        >
          <highlight-icon
            v-if="sublist.highlightsTag || sublist.highlightsSearch"
            :variant="
              sublist.highlightsTag
                ? sublist.highlightsSearch
                  ? 'both'
                  : 'primary'
                : 'secondary'
            "
          >
            <p>
              <strong>Uwaga.</strong>
              {{ getTooltipSingularWord(list) || 'Ustawa jest objęta' }} Twoim
              monitoringiem.
            </p>
          </highlight-icon>
          <div v-html="sublist.title" />
        </div>

        <div v-else>
          <button
            @click="sublist.modalOpen = true"
            class="sublist-item modal-open"
          >
            <highlight-icon
              v-if="sublist.highlightsTag || sublist.highlightsSearch"
              :variant="
                sublist.highlightsTag
                  ? sublist.highlightsSearch
                    ? 'both'
                    : 'primary'
                  : 'secondary'
              "
            >
              <p>
                <strong>Uwaga.</strong>
                {{ getTooltipSingularWord(list) || 'Ustawa jest objęta' }} Twoim
                monitoringiem.
              </p>
            </highlight-icon>
            {{ sublist.title }}
          </button>
          <base-modal
            :open="sublist.modalOpen"
            @close="sublist.modalOpen = false"
          >
            <div v-html="sublist.paragraphs" />
          </base-modal>
        </div>
      </div>
    </base-accordion>

    <base-spinner v-if="loading" />
  </div>
</template>

<script>
import HighlightIcon from './HighlightIcon';
import InfoListShowText from '@/components/Item/components/InfoListShowText.vue';

export default {
  components: {
    HighlightIcon,
    InfoListShowText,
  },
  props: {
    loading: Boolean,
    infos: Array,
    lists: Array,
    title: String,
  },
  methods: {
    getTooltipPluralWord(list) {
      return list?.highlightTooltipName?.plural;
    },
    getTooltipSingularWord(list) {
      return list?.highlightTooltipName?.singular;
    },
    toggleOpenInfo(index) {
      if (index !== -1) {
        this.toggleOpenList(-1);
      }
      this.infos.forEach((info, infoIndex) => {
        info.open = index === infoIndex && !info.open;
      });
    },
    toggleOpenList(index) {
      if (index !== -1) {
        this.toggleOpenInfo(-1);
      }
      this.lists.forEach((list, listIndex) => {
        list.open = index === listIndex && !list.open;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sublist {
  &::after {
    content: '';
    display: block;
    width: 75%;
    border-bottom: 3px solid $gray-5;
  }
  &:last-child::after {
    display: none;
  }
}
.sublist-item {
  padding: 0.8rem 0;
}
.sublist-item--title-only {
  display: flex;
}
.modal-open {
  display: flex;
  position: relative;
  width: 100%;
  padding-right: 2.5rem;
  margin-right: 2rem;
  font-size: 1.14rem;
  text-align: left;
  transition: color 0.2s;

  &:hover {
    color: $primary;

    &::before {
      border-color: $primary;
    }
  }

  &::before {
    @include arrowPseudo($gray-3, 'right');
    position: absolute;
    right: 0.2rem;
    top: 50%;
    margin-top: -0.3em;
  }
}
</style>
